.bottom {
  position: absolute;
  bottom: 0;
}

.left {
  &--105-neg {
    left: -105px !important;
  }

  &--166-neg {
    left: -166px !important;
  }
}