@import "./_colors.scss";

.Setup {
  padding: 40px;
  width: 100vw;
  margin: auto;

  .AboutYourTools {
    .form-group {
      label {
        margin-bottom: 16px;
      }
    }
    ul {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        display: flex;
        margin-bottom: 8px;

        .CheckBox {
          .checkmark {
            border: 2px solid $primary;

            &.error {
              border: 2px solid red !important;
            }
          }
        }

        p {
          margin: 0;
          display: inline-block;
          text-transform: capitalize;
        }
      }
    }

    input[type="radio"] {
      &.error {
        &:checked:after {
          background-color: $accent-1;
          content: "";
          width: 15px;
          height: 15px;
          position: relative;
          visibility: visible;
          display: inline-block;
          border-radius: 15px;
          top: -2px;
          left: 2px;
          border: 2px solid white;
        }
      }
    }
  }

  .header {
    margin-bottom: 40px;

    .logo {
      margin: auto;
      width: 193px;
    }
  }

  .subtitle.friends {
    font-weight: 300;
  }
  .full {
    flex-basis: 100%;
  }
  .invite-email {
    flex-basis: 90%;
  }
  .delete-invite {
    flex-basis: 10%;
    button.btn {
      color: grey;
      margin: 0;
    }
  }
  .add-invite {
    width: 200px;
    button {
      padding-left: 0;
      text-align: left;
      font-weight: 400;
    }
  }
  .hidden {
    display: none;
  }

  .body {
    margin: auto;
    max-width: 600px;
  }

  form {
    margin-top: 20px;

    .form-group {
      label {
        text-transform: none;
      }
    }
  }

  .ProgramGoals {
    margin: 20px 0;

    ul {
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        display: block;
        width: 50%;

        .CheckBox {
          .checkmark {
            border: 2px solid $primary;
          }
        }

        label {
          width: 20px;
          display: inline-block;
        }

        p {
          display: inline-block;
          text-transform: capitalize;
        }
      }
    }

    p {
      display: inline-block;
      text-indent: 4px;
      margin-bottom: 4px;
    }

    form {
      .form-group {
        position: relative;
        p {
          display: block;
        }

        .btn.btn-link {
          float: right;
          margin-right: 12px;
        }

        .add-form {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          flex-direction: column;

          .form-control.error {
            margin-top: 0;
          }

          div.error {
            margin-top: 4px;
          }

          div {
            &:first-child {
              width: 488px;
              max-width: 100%;
              margin-right: 12px;
            }

            &:last-child {
              .btn.btn-primary {
                width: 100px;
                height: 52px;
                padding: 0;
              }

              @media screen and (max-width: 800px) {
                .btn.btn-primary {
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }

    .btn.btn-link {
      padding: 0;
      font-size: 14px;
      // width: auto;
      display: inline-block;
      position: relative;
      top: 6px;
      margin-left: 8px;
      font-weight: normal;

      i {
        font-size: 12px;
        margin-right: 4px;
        position: relative;
        top: -1px;
      }
    }
  }

  .AccountSettings {
    margin-top: 12px;

    section {
      padding: 12px;
      margin: 12px 0;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      &.blue {
        background-color: #eef5fc;
      }

      label.CheckBox {
        position: relative;
        top: 12px;

        .checkmark {
          border: 2px solid $primary;
        }
      }

      .body {
        padding: 8px;

        h3 {
          font-weight: 300;
          font-size: 20px;
          color: $primary-dark;
          margin: 0;
        }
      }
    }
  }

  .Finish {
    margin-top: 20px;
    text-align: center;

    .btn.btn-primary {
      margin: 20px 0;
    }
  }
}
