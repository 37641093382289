.w {
  &--full {
    width: 100%;
  }

  &--unset {
    width: unset;
  }

  &--80 {
    width: 80%;
  }

  &--70 {
    width: 70%;
  }

  &--50 {
    width: 50%;
  }

  &--30 {
    width: 30%;
  }

  &--80 {
    width: 80px;
  }

  &--160 {
    width: 160px;
  }

  &--200 {
    width: 200px;
  }

  &--210 {
    width: 210px;
  }

  &__calc--300 {
    width: calc(100% - 300px);
  }
}
