.p {
  &__zero {
    padding: 0;
  }

  &__safe {
    &-t {
      padding-top: 20px;
    }

    &-h {
      padding-left: 213px;
      padding-right: 100px;
    }
  }

  &__sm {
    padding: 20px;
  }

  &t {
    &__sm {
      padding-top: 20px;
    }

    &__md {
      padding-top: 50px;
    }
  }

  &b {
    &__xsm {
      padding-bottom: 12px;
    }

    &__sm {
      padding-bottom: 20px;
    }

    &__md {
      padding-bottom: 42px;
    }
  }

  &l {
    &__zero {
      padding-left: 0 !important;
    }

    &__xsm {
      padding-left: 12px;
    }

    &__sm {
      padding-left: 20px;
    }
  }

  &r {
    &__14 {
      padding-right: 14px !important;
    }

    &__sm {
      padding-right: 20px;
    }
  }
}