.checkbox {
  display: flex;
  align-items: center;

  & label {
    margin: 0;
    padding: 0;

    & .label {
      margin-left: 10px;
    }

    & > input {
      display: none;
    }

    & > .box {
      border: 1px solid $primary;
      height: 15px;
      width: 15px;
      display: inline-block;
      border-radius: 4px;
    }

    input:checked ~ .box {
      background: $primary;

    }

    input:checked {
      & ~ .sales_results_trafic {
        background-color: #2A88F2;
        border-color: #2A88F2;
      }

      & ~ .sales_result_conversion {
        background-color: #89CAD7;
        border-color: #89CAD7;
      }

      & ~ .sales_result_conversion_normalized {
        background-color: #B8D8FF;
        border-color: #B8D8FF;
      }

      & ~ .new_cases {
        background-color: #ff5a71;
        border-color: #ff5a71;
      }

      & ~ .retail_traffic {
        background-color: #757E8E;
        border-color: #757E8E;
      }

      & ~ .retail_conversion {
        background-color: #2F4760;
        border-color: #2F4760;
      }

      & ~ .travel_traffic {
        background-color: #CEC7E3;
        border-color: #CEC7E3;
      }

      & ~ .travel_conversion {
        background-color: #A7A0E8;
        border-color: #A7A0E8;
      }

      & ~ .financial_traffic {
        background-color: #8EDEB0;
        border-color: #8EDEB0;
      }

      & ~ .financial_conversion {
        background-color: #25BE6F;
        border-color: #25BE6F;
      }
    }
  }
}