$primary-light: #e9f3ff;
$primary: #2a88f2; // blue
$primary-dark: #2f4760;

$accent-1-light: #ffbdc6; // light pink
$accent-1: #ff5a71; // magenta (in logo)
$accent-2-light: #8edeb0; // light green
$accent-2: #25be6f; // green
$accent-3: #f4b974; // gold/yellow (in logo)
$accent-3-dark: #f5a623; // dark gold
$accent-4-light: #cec7e3; // light purple (in logo)
$accent-4: #a79fe8; // purple (in logo)
$accent-5: #89cad7; // teal (in logo)

$placeholder-color: #eaeaea;

/*
  grays

  #333
  #757e8e <- in styleguide
  #aeaeae <- in styleguide
  #eaeaea <- in styleguide

  #b7c0cc
  #71798b
  #e9f3ff
  #495057
  #f0f0f0
  #f6fafd
  #4a4a4a
  #aaaaaa
  #bac3ce
  #fcfcfc
  #f1f6fd
  #fbfbfb
  #d8d8d8
  #f9fdff
  #eee
  #b0bac7
  #818a98
  #fbfcfc
  #dcdbdb
  #f0f3f4
  #f3f3f3
  #d1d2d1
  #222
  #f5f5f5
  #eef5fc
  #b9bdc3
  #ddd
  #515151
  #d6d6dd
  #040506
  grey

  #ffffff
  #fff
*/
