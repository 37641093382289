@import "_vars";
@import "./_colors.scss";

.MainNav {
  min-height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;

  a.logo {
    display: block;
    height: 60px;

    img {
      object-fit: contain;
      margin: 0 20px 0 30px;
      position: relative;
      top: 18px;
    }
  }

  ul.nav-items {
    list-style: none;
    margin: 0;
    display: inline-block;
    height: 60px;
    min-width: 400px;

    li {
      display: inline-block;
      margin-right: 40px;
      height: inherit;

      @media (max-width: 960px) {
        margin-right: 20px;
      }

      a {
        color: #b0bac7;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        height: inherit;
        vertical-align: middle;
        display: table-cell;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

      &.active {
        a {
          color: $primary;
        }

        border-bottom: 3px solid $primary;
      }
    }
  }

  .settings {
    margin-left: auto;
    height: inherit;
    display: flex;
    height: 60px;

    .tasks {
      position: relative;
      .circle.notifications {
        position: absolute;
        border-radius: 50%;
        top: 24px;
        left: 8px;
        background: $accent-1;
        border: 2px solid #fff;
        color: white;
        font-weight: bold;
        font-size: 10px;
        width: 18px;
        height: 18px;
        text-align: center;
        z-index: 1;
        line-height: 16px;
      }
    }

    .alert {
      display: table-cell;
      height: 60px;
      font-size: 16px;
      color: #eaeaea;
      vertical-align: middle;
      letter-spacing: 0.6px;
      padding-right: 20px;
      top: 3px;
    }

    .Avatar {
      position: relative;
      display: inline-block;
      width: 30px;
      vertical-align: middle;
      top: 3px;
      max-height: 60px;

      .user-name {
        display: none !important;
      }

      &:hover {
        .user-name {
          display: block !important;
          background-color: #757e8e;
          padding: 4px 12px 4px 12px;
          color: #fff;
          font-size: 12px;
          position: absolute;
          border-radius: 14px;
          text-transform: capitalize;
          white-space: nowrap;
          z-index: 1000;
        }
      }

      .user {
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        color: #818a98;
        font-size: 10px;
        justify-content: center;

        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          left: -3px;
          top: -3px;
          border: 1px solid #eaeaea;
        }
      }
    }

    .profile-toggle {
      display: flex;
      height: 60px;
      font-size: 13px;
      color: $primary-dark;
      font-weight: bold;
      align-items: center;
      letter-spacing: 0.6px;
      margin-right: 20px;

      .dropdown {
        button {
          background-color: transparent;
          border: 0;
          color: $primary-dark;

          &:after {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: 0.155em;
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }
        }

        .dropdown-menu {
          position: absolute;
          left: -230px;
          top: -6px;
          min-width: 275px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .company-display {
      background-color: $primary;
      min-width: 140px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      line-height: 60px;
      font-weight: bold;
      letter-spacing: 0.4px;
      font-size: 12px;
    }

    button.btn.btn-add {
      margin-left: 40px;
      display: table-cell;
      background-color: $primary;
      border-radius: 0;
      height: 60px;
      width: 60px;
      max-width: 60px;
      min-width: 60px;
      padding: 0;
      margin-bottom: 0;
      color: #fff;

      &:hover {
        background-color: $primary-dark;
      }
    }
  }
}

.ShareNav {
  margin-left: auto;

  .profile-toggle {
    display: flex;
    height: 60px;
    font-size: 13px;
    color: $primary-dark;
    font-weight: bold;
    align-items: center;
    letter-spacing: 0.6px;
    margin-right: 20px;

    .dropdown {
      .dropdown-button {
        display: flex;
        align-items: center;
      }

      button {
        background-color: transparent;
        border: 0;
        color: $primary-dark;

        &:after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          vertical-align: 0.155em;
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          margin-left: 12px;
        }
      }

      .dropdown-menu {
        position: absolute;
        left: -60px;
        top: 0px;
        min-width: 140px;

        .dropdown-menu-item-btn {
          &:after {
            border: 0px;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.PublicNav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 8px 30px;
  background: white;
  z-index: 10;

  & > div.PublicNav-container {
    margin: 0;
  }

  .small-logo,
  a.btn {
    margin: 0;
  }

  a.btn {
    padding: 8px 14px;
  }
}
