.EditorInput {
  position: relative;
  min-height: 40px;

  * {
    color: #757e8e;
  }

  &.writeable {
    border: 2px dashed transparent;
    border-radius: 4px;
    padding-left: 10px;

    &:focus {
      border: 2px dashed #eaeaea;
    }

    &:hover {
      border: 2px dashed #eaeaea;
      cursor: pointer;
    }
  }

  // &.always-focused {
  //   border: 2px dashed #eaeaea;
  // }

  &.visible-toolbar {
    &.has-selection {
      .Toolbar {
        opacity: 1;
      }
    }
    .Toolbar {
      opacity: .5;
    }

    .DraftEditor-root {
      padding-top: 45px;
    }
  }

  &.textarea {
    border: 2px solid #eaeaea !important;
    background: white;
    border-radius: 4px;

    &.error {
      border-color: #ff5a71 !important;
    }

    &:focus-within {
      border-color: #2a88f2 !important;
    }

    & .DraftEditor-editorContainer {
      & > div {
        min-height: 120px;;
      }
    }
  }

  .DraftEditor-root {
    color: $primary-dark;
    padding: 8px 12px 8px 4px;

    h1, h1 * {
      font-size: 18px !important;
      letter-spacing: 0.4px;
      color: $primary-dark !important;
    }

    h2 {
      font-size: 15px !important;
      letter-spacing: 0.2px;
      color: $primary-dark !important;
      font-weight: 500 !important;
    }

    span {
      font-size: 13px;
    }

    .public-DraftEditorPlaceholder-inner {
      font-style: italic;
    }

    .link {
      color: $primary !important;
      text-decoration: none;
      display: inline !important;

      * {
        color: $primary !important;
        font-weight: 700;
      }
    }
  }

  .Toolbar {
    position: absolute;
    z-index: 2;
    border-radius: 4px;
    background-color: #333;
    display: flex;
    flex-direction: row;
    width: 240px;
    -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
    box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);

    ul.toolbar-items {
      margin: 0;
      padding: 0;
      width: auto;

      li {
        display: table-cell;
        vertical-align: middle;
        height: 28px;
        width: 40px;
        color: #fff;
        text-align: center;
        vertical-align: middle;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          border-right: 1px solid #515151;
        }

        i {
          font-size: 14px;
        }

        label {
          font-size: 15px;
          font-weight: bold;
          margin: 0;
          color: #fff;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }

        &.active {
          background-color: #fff;
          border-radius: 8px;

          i,
          label {
            color: #333 !important;
          }
        }

        &.header-one, &.header-two {
          label {
            color: #757e8e;
          }

          &.active {
            color: #333 !important;
          }
        }
      }
    }

    ul:last-child {
      li:last-child {
        border-right: 1px solid transparent;
      }
    }
  }
}