$upload_size: 150px;

.file_uploader {
  cursor: pointer;
  display: block;
  height: 200px;
  width: 200px;
  margin: 0 8px 8px 0;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  border-radius: 4px;
  border: 4px dashed #eaeaea;
  text-align: center;

  &.active {
    border: 4px dashed $primary;
    i,
    p {
      color: $primary;
    }
  }

  &.error {
    border: 4px dashed $accent-1;
    i,
    p {
      color: $accent-1;
    }
  }

  .item-dropbox {
    position: relative;
    height: 200px;
    width: 200px;
    margin: auto;
    top: 38px;
    padding: 12px;

    i {
      color: #f3f3f3;
      font-size: 60px;
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &.no-images {
    width: 100%;
    background-color: #fff;
    margin: auto;

    .item-dropbox {
      width: 100%;
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  &.over {
    border: 4px dashed $accent-2;
    i,
    p {
      color: $accent-2;
    }
  }

  &:hover {
    cursor: pointer;
    border: 4px dashed $primary;
    i,
    p {
      color: $primary;
    }
  }
}