.m {
  &b {
    &r {
      &-05 {
        margin-bottom: .5rem !important;
      }
    }
  }
  &0 {
    margin: 0;
  }

  &t {
    &__xxsm {
      margin-top: 12px;
    }

    &__sm {
      margin-top: 25px;
    }
  }

  &l {
    &__sm {
      margin-left: 19px !important;
    }
  }

  &r {
    &__sm {
      margin-right: 19px !important;
    }
  }

  &v {
    &__sm {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}