.icon {
  &.circular {
    border: 1px dashed #757e8e;
    padding: 5px;
    border-radius: 10px;
  }
}

i {
  &.active {
    color: $primary;
  }
}