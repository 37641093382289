.btn {
  &-primary {
    &.inverse {
      color: #2a88f2;
      background-color: transparent !important;

      &:focus {
        color: #2a88f2;
        background-color: transparent !important;
      }

      &:hover {
        color: #2a88f2;
        background-color: transparent !important;
      }
    }
  }

  &-no-transform {
    text-transform: none !important;
  }
}

.button {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: auto;
  padding: 8px 40px;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;

  &.disabled {
    opacity: 0.5;
  }

  &__primary {
    background-color: #2a88f2;
    color: #fff !important;
    border: 1px solid transparent;
  }

  &__primary-reverse {
    background-color: transparent;
    color: #2a88f2 !important;
    border: 1px solid #2a88f2;
  }

  &__secondary {
    color: #2a88f2 !important;
    border: 0px;
    background-color: transparent;
    font-size: 14px;
    text-decoration: underline;
  }

  &__link {
    color: #2a88f2 !important;
    padding-left: 0;
    border: 0px;
    background-color: transparent;
    font-size: 14px;
    text-decoration: underline;
  }
}
