.BarChart {
  position: relative;
  width: 100% !important;

  .Hint {
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    padding: 8px;

    h3 {
      font-size: 12px;
      display: inline-block;
      text-transform: capitalize;
    }

    p {
      color: #fff;
      display: inline-block;
      margin-left: 4px;
    }
  }
}
