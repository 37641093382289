@import "./_colors.scss";

.Aside {
  &.exp {
    width: 500px;
    min-width: 500px !important;
  }

  .Experiment {
    width: 100%;

    hr.dashed {
      margin: 0 16px;
      border: 0;
      border-bottom: 1px dashed #eaeaea;
    }

    section {
      margin-top: 8px;
      padding: 0 20px;

      .userInfo {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        position: relative;
        padding-left: 16px;

        .img {
          margin-right: 12px;

          .avatar-stack {
            display: flex;
            flex-direction: row-reverse;
          }

          .Avatar {
            width: auto;
            position: relative;

            .user-name {
              display: none !important;
            }

            &:hover {
              .user-name {
                display: block !important;
                background-color: #757e8e;
                padding: 4px 12px 4px 12px;
                color: #fff;
                font-size: 12px;
                position: absolute;
                border-radius: 14px;
                text-transform: capitalize;
                white-space: nowrap;
                z-index: 1000;
              }
            }

            &:last-child {
              left: 0px !important;
            }

            .user {
              min-width: 32px;
              max-width: 32px;
              height: 32px;

              &:before {
                height: 40px;
                width: 40px;
                left: -4px;
                top: -4px;
              }
            }
          }
        }
      }

      &.header {
        display: table;
        width: 100%;
        margin-bottom: 8px;
        margin-top: 20px;

        .left {
          display: table-cell;
          vertical-align: middle;

          .ExpStatus {
            position: relative;
            left: 16px;
          }

          label {
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            color: #aaaaaa;
            padding-left: 16px;
          }

          div.status-btns {
            display: flex;
            padding-left: 6px;

            .btn.btn-status {
              width: 30px;
              padding: 8px !important;
              height: 30px;
              font-size: 16px;
              letter-spacing: 0.6px;
              text-transform: uppercase;
              color: #eaeaea;
              border: 1px solid #fff;
              background-color: #fff;

              &.love {
                i.fa-heart {
                  color: $accent-1;
                }
              }

              &.back_burner {
                i.fa-fire-alt {
                  color: $accent-3-dark;
                }
              }
            }
          }
        }

        .right {
          width: 50%;
          display: table-cell;
          vertical-align: middle;

          .controls {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 8px;

            .dropdown {
              padding: 4px;

              .dropdown-menu {
                left: -180px;
                top: -0px;
              }
            }
          }
        }
      }

      &.experiences {
        margin-top: 24px;
        padding: 0 20px 20px 20px;

        label {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.4px;
          color: #71798b;
          padding: 0 16px;
        }

        ul {
          padding: 0 16px;
          margin: 0;

          li {
            display: table;

            div {
              position: relative;
              display: table-cell;
              width: 100%;
              font-weight: 500;

              &:last-child {
                font-weight: 400;
                text-align: right;
              }

              .fa-star {
                position: absolute;
                left: -20px;
                top: 2px;
                font-size: 12px;
                color: $accent-3-dark;
                display: inline-block;
              }

              p {
                color: $primary-dark;
                font-size: 13px;
                margin-bottom: 4px;
              }
            }
          }
        }
      }

      &.title-bar {
        display: table;
        margin-bottom: 12px;

        div {
          display: table-cell;
          width: 100%;
          vertical-align: top;

          &.first-child {
            max-width: 80%;
          }
        }

        p.date {
          font-size: 12px;
          color: #aaaaaa;
          margin-bottom: 2px;
          padding-left: 16px;
        }

        .ExpIcon {
          position: relative;
          left: -12px;
          top: 26px;
        }

        h2 {
          font-size: 25px;
          font-weight: 500;
        }

        .text_input {
          padding: 8px 12px 0 12px;
          display: inline-block;
          width: calc(100% - 40px);

          &.edit {
            border: 0;
            padding: 0;
          }
        }
      }

      &.questions {
        margin-top: 24px;

        .question {
          margin-bottom: 20px;

          label {
            text-transform: none;
            color: #71798b;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
            padding: 0 16px;
          }

          p {
            margin-bottom: 4px;
            padding: 0 16px;
          }

          &.board {
            padding: 0 12px;
            position: relative;

            .btn.btn-link {
              position: absolute;
              width: auto;
              font-weight: normal;
              right: 8px;
              font-size: 11px;
              top: -8px;
            }

            label {
              padding: 0;
            }

            .SelectInput {
              position: relative;
              top: -4px;
            }
          }
        }
      }
    }
  }
}

.ExperimentLifts {
  & label {
    cursor: pointer;
    text-transform: none;
    color: #71798b;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    // padding: 0 16px;
  }

  & .arrow {
    fill: #71798b;
  }

  & p {
    padding: 0 16px;
    font-weight: normal;
  }

  & .more-reports {
    margin-bottom: 8px;
  }

  & .success {
    color: $accent-2;
  }

  & .fail {
    color: $accent-1;
  }
}

section.Kanban {
  padding: 20px;
  height: calc(100vh - 160px);
  width: 100%;

  div.board {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);

    .header {
      padding: 12px 10px 4px 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      .controls {
        position: absolute;
        top: 12px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        padding-right: 20px;

        .dropdown {
          .dropdown-menu {
            top: -8px;
            left: -190px;
          }
        }
      }

      label {
        text-transform: uppercase;
        color: #bac3ce;
        font-size: 11px;
        font-weight: 500;
        margin: 0 0 8px 0;
        letter-spacing: 0.6px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }

      h3 {
        font-size: 22px;
        font-weight: 300;
        margin: 0;
        color: $primary-dark;
        text-transform: capitalize;
      }
    }

    div.columns {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      height: 100%;
      position: relative;

      div.column {
        width: 100%;
        border-right: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
        overflow-y: scroll;

        div.head {
          width: 100%;
          text-align: center;
          background-color: #fff;
          display: table-cell;
          padding: 12px;
          border: 1px solid #eaeaea;
          border-right: 0;
          border-left: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: $primary-dark;
          vertical-align: middle;

          i {
            font-size: 22px;
            margin-right: 8px;

            &.fa-heart {
              color: $accent-1;
            }

            &.fa-fire-alt {
              color: $accent-3-dark;
            }

            &.fa-lightbulb {
              color: #aeaeae;
            }
          }

          span {
            display: inline-block;
            position: relative;
            top: -4px;
          }
        }

        div.items {
          display: block;
          padding: 8px;
          overflow-y: scroll;
          max-height: 100%;
          flex: 1 1 auto;
          min-height: 0;

          div.item {
            display: block;

            width: 100%;
            margin-top: 8px;

            div.content {
              width: 100%;
              padding: 16px;
              font-size: 12px;
              background-color: #fff;
              border-radius: 4px;
              border: 1px solid #eaeaea;

              &.active {
                -webkit-box-shadow: 2px 2px 6px #eaeaea;
                -moz-box-shadow: 2px 2px 6px #eaeaea;
                box-shadow: 2px 2px 6px #eaeaea;
              }

              div {
                display: table-cell;
                vertical-align: top;
                width: auto;

                p {
                  font-size: 12px;
                  line-height: 14px;
                  margin: 0;

                  &.description {
                    font-size: 12px;
                    line-height: 18px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-width: 800px;
                  }
                }

                &.body {
                  width: 100%;

                  h4 {
                    font-size: 14px;
                    color: $primary-dark;
                  }

                  ul {
                    list-style: none;
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    li {
                      font-size: 12px;
                      margin: 0;
                      padding: 0;
                      background-color: transparent;
                      line-height: 20px;

                      label {
                        margin-bottom: 0;
                        color: #757e8e;
                        margin-right: 4px;
                      }

                      p {
                        display: inline-block;
                        margin: 0 4px 0 0;

                        font-size: 12px;
                        color: $primary-dark;
                        padding: 0;

                        &:after {
                          content: ",";
                        }

                        &:last-child {
                          font-size: 12px;

                          &:after {
                            content: "";
                          }
                        }
                      }
                    }
                  }
                }

                i {
                  text-align: center;
                  color: #eaeaea;
                  font-size: 18px;

                  &:hover {
                    cursor: pointer;
                    color: $accent-3;
                  }
                }

                .active {
                  color: $accent-3;
                }

                &:first-child {
                  text-align: center;
                  padding-right: 12px;
                }

                &:last-child {
                  text-align: center;
                  vertical-align: middle;
                  padding-left: 20px;
                  padding-right: 14px;

                  h4 {
                    font-size: 20px;
                    font-weight: 300;
                  }
                }
              }
            }

            &:hover {
              cursor: pointer;
            }

            &:active {
              cursor: grabbing;
            }

            &.dragging {
              .content {
                border: 1px solid #eaeaea;
                -webkit-box-shadow: 2px 2px 6px #eaeaea;
                -moz-box-shadow: 2px 2px 6px #eaeaea;
                box-shadow: 2px 2px 6px #eaeaea;

                -webkit-transform: rotate(-2deg);
                -ms-transform: rotate(-2deg);
                transform: rotate(-2deg);
              }
            }
          }
        }

        &:last-child {
          border-right: 0;

          div.head {
            border-right: 1px solid #eaeaea;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        &:first-child {
          div.head {
            border-left: 1px solid #eaeaea;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
  }
}

.ResultsTable {
  width: 100%;
  border: 1px solid #eaeaea;
  background-color: #fff;

  .ResultsTableFooter {
    width: 100%;
    padding: 8px 8px 8px 20px;

    .btn.btn-link {
      width: unset;
      font-size: 12px;
      text-align: left;
      padding: 0;
      margin: 0;
      font-weight: 400;

      i {
        margin-right: 4px;
      }
    }
  }

  .ResultsTableWrapper {
    padding: 0;
    margin: 0;
    display: flex;
    background-color: #fff;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;

    ul {
      margin: 0;
      padding: 0;

      &.ControlColumn {
        position: absolute;
        z-index: 1;
        background-color: #fff;
      }

      &.ResultsMetrics {
        position: relative;
        left: 300px;
        display: flex;
        z-index: 0;
        width: calc(100% - 300px);

        &:nth-child(even) {
          &.Column .Header {
            background-color: #f6fafd;
          }
        }
      }

      li {
        background-color: #fff;
        width: 300px;

        &:nth-child(even) {
          &.Column .Header {
            background-color: #f6fafd;
          }
        }

        &.Column {
          position: relative;
          width: 100%;
          min-width: 300px;
          border-right: 1px solid #eaeaea;
          position: relative;

          &.Control {
            //max-width: 340px;
          }

          .Header {
            padding: 20px;
            height: 70px;
            text-align: center;
            color: $primary-dark;
            font-size: 13px;
            letter-spacing: 0.4px;
            font-weight: bold;
          }

          .Ledger {
            height: 40px;
            padding: 12px;
            display: flex;
            width: 100%;
            background-color: #fcfcfc;
            border-bottom: 1px solid #d8d8d8;

            div {
              display: block;
              width: 100%;
              text-align: center;
              font-size: 10px;
              font-weight: bold;
              color: #757e8e;
              letter-spacing: 0.5px;
              text-transform: uppercase;
            }
          }

          .MetricWrapper {
            height: 64px;
            font-size: 12px;
            border-bottom: 1px solid #eaeaea;
            display: flex;
            flex-direction: column;

            &.active {
              background-color: #f6fafd;

              .ResultInput {
                input {
                  background-color: #f6fafd;
                }
              }

              .SelectInput button {
                background-color: transparent;
                font-weight: 500;
              }
            }

            input {
              width: 100%;
              border: 0px;
              height: 21px;
              text-align: center;
              font-size: 12px;
              padding: 0 8px;
              border: 1px solid transparent;

              &:hover {
                background-color: #f1f6fd;
                cursor: pointer;
              }

              &:focus {
                background-color: #fff;
                border: 1px solid $primary;
              }
            }
          }

          .Metric {
            height: 42px;
            font-size: 12px;
            padding: 0;
            border-bottom: 1px solid #eaeaea;
            background-color: #fff;
            display: flex;
            flex-direction: row;

            &.active {
              background-color: #f6fafd;

              .ResultInput {
                input {
                  background-color: #f6fafd;
                }
              }

              .SelectInput button {
                background-color: transparent;
                font-weight: 500;
              }
            }

            .ResultInput {
              width: 100%;
              position: relative;

              input {
                width: 100%;
                border: 0px;
                height: 41px;
                text-align: center;
                font-size: 12px;
                padding: 0 8px;
                border: 1px solid transparent;
                border-right: 1px solid #eaeaea;

                &:hover {
                  background-color: #f1f6fd;
                  cursor: pointer;
                }

                &:focus {
                  background-color: #fff;
                  border: 1px solid $primary;
                }
              }

              &.percent {
                &::before {
                  content: "%";
                  position: absolute;
                  float: right;
                  font-size: 12px;
                  line-height: 43px;
                  height: 41px;
                  right: 10px;
                  font-weight: bold;
                  color: #aeaeae;
                }
              }

              &.currency {
                &.dollar {
                  &::before {
                    content: "$";
                  }
                }

                &.euro {
                  &::before {
                    content: "€";
                  }
                }

                &.pound {
                  &::before {
                    content: "£";
                  }
                }

                &::before {
                  position: absolute;
                  font-size: 12px;
                  left: 10px;
                  line-height: 43px;
                  height: 41px;
                  font-weight: bold;
                  color: #aeaeae;
                }
              }

              &:last-child {
                input {
                  border-right: 1px solid transparent;

                  &:focus {
                    background-color: #fff;
                    border: 1px solid $primary;
                  }
                }
              }
            }

            &.Control {
              position: relative;
              display: flex;
              width: 100%;
              height: 64px;

              i.primary {
                padding: 8px 8px 0 0;
                height: 34px;
                position: relative;
                top: 6px;

                &.fa-star {
                  color: $accent-3;
                }
              }

              &.New {
                .SelectInput {
                  .menu {
                    left: -1px;
                  }
                }
              }

              .SelectInput {
                width: 100%;

                button {
                  margin: 0;
                  border: 1px solid transparent;
                  padding: 0;
                  font-size: 12px;
                  height: 41px;
                  text-transform: capitalize;

                  &:after {
                    font-family: "Font Awesome 5 Free";
                    content: "";
                  }

                  &::before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f078";
                    color: #757e8e;
                    font-style: normal;
                    font-weight: 900;
                    float: left;
                    margin-right: 8px;
                  }

                  &:hover {
                    color: $primary;

                    &::before {
                      font-family: "Font Awesome 5 Free";
                      content: "\f078";
                      color: #757e8e;
                      font-style: normal;
                      font-weight: 900;
                      float: left;
                      margin-right: 8px;
                    }
                  }
                }

                &.no-select {
                  button {
                    &::before {
                      content: "" !important;
                    }
                  }
                }

                .menu {
                  position: absolute;
                  top: 0px;
                  left: -39px;
                  min-width: 300px;

                  .search {
                    border-bottom: 1px solid #eaeaea;

                    input {
                      text-align: left;
                      font-size: 12px;
                      padding: 12px;
                      width: 100%;
                      border: 1px solid transparent;

                      &:hover {
                        background-color: #fff;
                      }

                      &:focus {
                        border: 1px solid transparent;
                      }
                    }
                  }
                }
              }

              .dropdown {
                padding-left: 12px;

                button.dropdown-button {
                  height: 41px;
                  color: #757e8e;
                  border: 0px;
                  background-color: transparent;
                }

                ul.dropdown-menu {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  min-width: 160px;

                  li {
                    min-width: 160px;
                    width: 100px;

                    &.active {
                      display: flex;
                      align-items: center;
                      &:after {
                        position: relative;

                        font-family: "Font Awesome 5 Free";
                        content: "\f00c";
                        font-size: 12px;
                        color: $accent-2;
                        font-style: normal;
                        font-weight: 900;
                        float: right;
                        margin-right: 8px;
                      }
                    }

                    button.dropdown-menu-item-btn {
                      font-size: 12px;

                      i {
                        color: #757e8e !important;
                      }

                      &.arrow-right {
                        &:after {
                          position: relative;
                          top: -2px;
                          font-family: "Font Awesome 5 Free";
                          content: "\f0de";
                          font-size: 16px;
                          color: #757e8e;
                          font-style: normal;
                          font-weight: 900;
                          float: right;
                          margin-right: 8px;
                          -webkit-transform: rotate(90deg);
                          -ms-transform: rotate(90deg);
                          transform: rotate(90deg);
                        }
                      }

                      &.arrow-up {
                        &:after {
                          position: relative;
                          top: -2px;
                          font-family: "Font Awesome 5 Free";
                          content: "\f0de";
                          font-size: 16px;
                          color: #757e8e;
                          font-style: normal;
                          font-weight: 900;
                          float: right;
                          margin-right: 8px;
                        }
                      }
                    }

                    .subMenu {
                      position: absolute;
                      background-color: #fff;
                      left: 159px;
                      margin-top: -40px;
                      white-space: nowrap;
                      width: 160px;
                      box-shadow: -2px 2px 8px #b7c0cc;

                      ul {
                        border: 1px solid #eaeaea;
                        border-left: 0px;
                        border-right: 1px !important;

                        li {
                          font-size: 13px;
                          text-align: left;
                          display: block;
                          width: 100%;
                          color: #757e8e;
                          text-transform: capitalize !important;
                          border-width: 0;
                          padding: 8px 8px 8px 12px;
                          font-weight: normal;
                          margin: 0;
                          border-radius: 0;

                          i {
                            margin-right: 8px;
                            font-size: 12px;

                            &.arrow-top {
                              &:after {
                                position: relative;
                                top: -2px;
                                font-family: "Font Awesome 5 Free";
                                content: "\f161";
                                font-size: 16px;
                                color: #757e8e;
                                font-style: normal;
                                font-weight: 900;
                                float: left;
                                margin-right: 8px;
                              }
                            }

                            &.arrow-bottom {
                              &:after {
                                position: relative;
                                top: -2px;
                                font-family: "Font Awesome 5 Free";
                                content: "\f882";
                                font-size: 16px;
                                color: #757e8e;
                                font-style: normal;
                                font-weight: 900;
                                float: left;
                                margin-right: 8px;
                              }
                            }

                            &.arrow-up {
                              &:after {
                                position: relative;
                                top: -2px;
                                font-family: "Font Awesome 5 Free";
                                content: "\f0de";
                                font-size: 16px;
                                color: #757e8e;
                                font-style: normal;
                                font-weight: 900;
                                float: left;
                                margin-right: 8px;
                              }
                            }

                            &.arrow-down {
                              &:after {
                                position: relative;
                                top: -2px;
                                font-family: "Font Awesome 5 Free";
                                content: "\f0de";
                                font-size: 16px;
                                color: #757e8e;
                                font-style: normal;
                                font-weight: 900;
                                float: left;
                                margin-right: 8px;
                                -webkit-transform: rotate(180deg);
                                -ms-transform: rotate(180deg);
                                transform: rotate(180deg);
                              }
                            }
                          }

                          &:hover {
                            cursor: pointer;
                            color: $primary;

                            i {
                              color: $primary;
                            }
                          }

                          &.disabled {
                            background-color: #fbfcfc;
                            cursor: not-allowed;
                            color: #757e8e;

                            i {
                              color: #757e8e;
                            }

                            &:hover {
                              cursor: not-allowed;
                              color: #757e8e;

                              i {
                                color: #757e8e;
                              }
                            }
                          }

                          &.active {
                            &:after {
                              position: relative;

                              font-family: "Font Awesome 5 Free";
                              content: "\f00c";
                              font-size: 12px;
                              color: $accent-2;
                              font-style: normal;
                              font-weight: 900;
                              float: right;
                              margin-right: 8px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.Experiment {
  .Insights {
    .btn.btn-primary-light {
      width: 160px;
      margin-left: auto;
      padding: 8px 8px 8px 8px;

      i {
        margin-right: 4px;
      }

      &:hover {
        color: #fff;
        background-color: $primary;
      }
    }

    .avatar-stack {
      display: flex;
      flex-direction: row-reverse;
    }

    .Avatar {
      width: auto;
      position: relative;

      .user-name {
        display: none !important;
      }

      &:hover {
        .user-name {
          display: block !important;
          background-color: #757e8e;
          padding: 4px 12px 4px 12px;
          color: #fff;
          font-size: 12px;
          position: absolute;
          border-radius: 14px;
          text-transform: capitalize;
          white-space: nowrap;
          z-index: 1000;
        }
      }

      &:last-child {
        left: 0px !important;
      }

      .user {
        min-width: 32px;
        max-width: 32px;
        height: 32px;

        &:before {
          height: 40px;
          width: 40px;
          left: -4px;
          top: -4px;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #eaeaea;
        margin-bottom: 20px;
        margin: auto;
        margin-bottom: 8px;
        padding: 22px;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;

        div.avatar {
          margin: 4px 22px;
        }

        div.insight {
          display: flex;
          padding-top: 4px;
          padding-right: 8px;

          &:before {
            display: inline-block;
            content: url("/img/quotes.png");
            position: relative;
            margin-right: 12px;
            top: 4px;
          }

          p {
            display: inline-block;
            font-family: "AmericanTypewriter";
            font-size: 24px;
            word-break: break-word;
            color: #4a4a4a;
            line-height: 28px;
            padding-right: 20px;
          }
        }

        div.controls {
          margin-left: auto;

          .dropdown-menu {
            left: -80px;
            top: -4px;
            min-width: 120px;

            li {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.List.Experiments {
  padding-top: 0px !important;
}

.list-view.Experiments {
  padding: 0 !important;

  .SearchBar {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #eaeaea;

    .search {
      &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        color: #aeaeae;
        font-style: normal;
        font-weight: 900;
        position: absolute;
        margin-left: 10px;
        margin-top: 8px;
      }

      input {
        text-indent: 20px;
        padding: 18px 14px 18px 14px;
        font-size: 14px;
      }
    }
  }
}

section.Table.Experiments {
  width: 100%;
  padding: 20px;
  position: relative;
  overflow-y: hidden !important;

  .search {
    position: fixed;
    width: calc(100% - 259px);

    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f002";
      color: #aeaeae;
      font-style: normal;
      font-weight: 900;
      position: absolute;
      margin-left: 10px;
      margin-top: 8px;
    }

    input {
      text-indent: 20px;
      padding: 18px 14px 18px 14px;
      font-size: 14px;
    }
  }

  .scroll-container {
    height: calc(100vh - 161px);
    margin-top: 20px;
    overflow: hidden;
  }

  .table-wrapper {
    position: relative;
    top: 40px;

    table.table {
      border-collapse: collapse;
      border: 1px solid #eaeaea;
      width: 100%;
      position: relative;

      thead {
        width: 100%;
        display: block;

        tr {
          display: flex;

          th {
            background-color: #fbfbfb;
            font-size: 11px;
            text-transform: uppercase;
            color: #757e8e;
            position: sticky;
            letter-spacing: 0.4px;
            top: 0;
            z-index: 2;
            flex-grow: 1;
            flex-basis: 0;
            border-bottom: 1px solid #eaeaea !important;
            border-top: 0px;

            &.centered {
              text-align: center;
            }
          }
        }
      }

      tbody {
        display: block;
        width: 100%;
        max-height: calc(100vh - 300px);
        overflow-y: scroll;

        tr {
          display: flex;

          &:hover {
            cursor: pointer;

            td {
              background-color: $primary-light;
            }
          }

          td {
            font-size: 12px;
            vertical-align: middle;
            color: $primary-dark;
            flex-grow: 1;
            flex-basis: 0;

            &.centered {
              text-align: center;
            }

            &.name {
              width: 100%;
            }

            i.fa-star.active {
              color: $accent-3;
            }
          }

          &:last-child {
            td {
              border-bottom: 1px solid #eaeaea;
            }
          }
        }
      }
    }
  }
}

.ResultDescription {
  &.active {
    input {
      background-color: #f6fafd;
    }
  }
}

.action-create {
  button {
    text-wrap: nowrap;
  }
}