.itable {
  display: table;

  & .left {
    display: table-cell;
    vertical-align: middle;
  }

  & .right {
    width: 50%;
    display: table-cell;
    vertical-align: middle;
  }
}