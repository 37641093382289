@import '~bootstrap/scss/bootstrap';
@import '~react-vis/dist/style';

@font-face {
  font-family: 'AmericanTypewriter';
  src: url('/fonts/AmericanTypewriter.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

h1, h2 {
  font-weight: 300;
}
