@import '_vars';
@import '_animations';
@import '_colors.scss';

.Modal {
  position: absolute;
  width: 100%;

  .modal-box {
    width: 100%;
    height: 100vh;
    position: fixed;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1010;
    -webkit-animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    .content {
      display: block;
      min-width: 600px;
      width: fit-content;
      background-color: #fff;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      box-shadow: 8px 14px 16px #757e8e;
      padding: 20px;
      position: relative;
      overflow: auto;
      // top: -100px;
      max-height: 90%;

      .warning {
        position: relative;
        float: right;
        top: 12px;
        left: 40px;
        background-color: #fff;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        text-align: center;
        line-height: 70px;
        font-size: 60px;
        color: $accent-3;
        font-weight: bold;
        border: 4px solid $accent-3;
        box-shadow: 8px 4px 8px #eaeaea;

        i {
          position: relative;
          top: -6px;
          font-size: 36px;
        }
      }

      .danger {
        position: relative;
        float: right;
        top: 12px;
        left: 40px;
        background-color: #fff;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        text-align: center;
        line-height: 70px;
        font-size: 60px;
        color: $accent-1;
        font-weight: bold;
        border: 4px solid $accent-1;
        box-shadow: 8px 4px 8px $accent-1;

        i {
          position: relative;
          top: -6px;
          font-size: 36px;
        }
      }

      .success {
        position: relative;
        float: right;
        top: 12px;
        left: 40px;
        background-color: #fff;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        text-align: center;
        line-height: 70px;
        font-size: 60px;
        color: $accent-2;
        font-weight: bold;
        border: 4px solid $accent-2;
        box-shadow: 8px 4px 8px #eaeaea;

        i {
          position: relative;
          top: -6px;
          font-size: 36px;
        }
      }

      .primary {
        position: relative;
        float: right;
        top: 12px;
        left: 40px;
        background-color: #fff;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        text-align: center;
        line-height: 70px;
        font-size: 60px;
        color: $primary;
        font-weight: bold;
        border: 4px solid $primary;
        box-shadow: 8px 4px 8px #eaeaea;

        i {
          position: relative;
          top: -6px;
          font-size: 36px;
        }
      }

      .header {
        padding: 12px 20px 0px 20px;
        max-width: 600px;

        h3 {
          color: $primary-dark;
        }
      }

      .panels {
        display: table;
        flex-direction: row;
        width: 800px;
        margin-top: 30px;
        padding: 20px;

        &:before {
          clear: both;
        }

        .left,
        .full {
          display: table-cell;
          width: 50%;
          padding-right: 20px;

          label {
            display: inline-block;
            font-size: 11px;
            margin: 0 4px 4px 0;
            text-transform: uppercase;
            letter-spacing: 0.4px;
            white-space: nowrap;
            color: #aaaaaa;
            font-weight: 500;
          }

          .header {
            padding: 0;
            margin-bottom: 20px;

            img {
              width: 60px;
              display: inline-block;
            }

            h2 {
              margin-left: 20px;
              display: inline-block;
              font-weight: 500;
              color: $primary-dark;
            }
          }

          i {
            font-size: 12px;
            margin-right: 4px;
          }

          ul,
          ol {
            padding: 4px 0 0 14px;
            margin-top: 12px;

            li {
              font-size: 14px;
              color: #757e8e;

              ul {
                margin: 0;
                padding: 8px 0 0 12px;
                list-style: circle;
              }
            }
          }
        }

        &.full {
          width: 100%;
        }

        .right {
          display: table-cell;
          width: 50%;
          padding-left: 20px;
          vertical-align: middle;

          &.top {
            vertical-align: top !important;
          }

          form {
            margin-top: 20px;

            .form-group {
              label {
                font-size: 13px;
              }
            }
          }
        }
      }

      .body {
        padding: 20px 20px 0px 20px;
        width: 800px;
        //border-top: 1px solid #eaeaea;

        ul {
          padding: 4px 2px 8px 2px;
          margin: 0;
          list-style: none;

          li {
            margin-bottom: 8px;

            label {
              margin: 0;
              font-size: 15px;
              color: $primary-dark;
              font-weight: 500;
            }
          }
        }
      }

      .btn.btn-close {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        text-align: center;
        font-size: 12px;
        color: #b0bac7;
        padding: 8px 5px 5px 5px;
        text-align: center;
        position: absolute;
        top: 12px;
        right: 12px;

        &:after {
          display: block;
          content: 'esc';
          font-size: 8px;
          position: relative;
          top: -8px;
          left: 0;
          margin-top: 4px;
          text-transform: uppercase;
          clear: both;
        }

        &:hover {
          background-color: #eaeaea;
          color: #b0bac7;
          box-shadow: 0px 0px transparent;
        }
      }
    }

    .body {
      max-height: 60vh;
      overflow-y: scroll;
    }

    .cntrl-bar {
      margin-top: 20px;
      border-top: 1px solid #eaeaea;
      padding: 14px 0 0px 0px;
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .btn {
        padding: 8px 16px;
        margin: 0;
        margin-left: 4px;
        margin-right: 4px;
        white-space: nowrap;
        width: auto;
        min-width: 140px;
        font-size: 14px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #757e8e;
    opacity: 0.7;
  }
}

.modal-enter .modal-box {
  opacity: 0.01;
}

.modal-enter.modal-enter-active .modal-box {
  -webkit-animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modal-leave .modal-box {
  opacity: 1;
}

.modal-leave.modal-leave-active .modal-box {
  -webkit-animation: scale-out-center 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out-center 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.Modal.Uploads {
  .content {
    max-height: calc(100vh - 100px);

    .body {
      margin-top: 12px;
      border-top: 1px solid #eaeaea;

      ul.FileList {
        max-height: calc(100vh - 400px);
        margin: 0;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 16px;
        overflow: scroll;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px dashed #eaeaea;
          padding: 8px 8px 12px 8px;

          i {
            color: #eaeaea;
            margin-right: 12px;
          }

          .title {
            a,
            p {
              font-size: 15px;
              margin: 0;
              padding: 0;
            }
          }

          .ctrls {
            margin-left: auto;

            .btn {
              padding: 0;
              margin: 0;

              &:hover {
                i {
                  color: $accent-1 !important;
                }
              }
            }
          }
        }
      }
    }

    .UploadBox {
      text-align: center;
      border: 4px dashed #eaeaea;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 12px;
      border-radius: 8px;

      &.active {
        border: 4px dashed $accent-2;
      }

      i.fa-cloud-upload-alt {
        font-size: 58px;
        margin-bottom: 8px;
        color: #eaeaea;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        color: #757e8e;
      }

      p {
        span.link {
          color: $primary;
        }
      }
    }
  }
}

.Modal.SignInUser {
  .content {
    min-width: 700px;
    max-width: 90%;

    .header {
      h3 {
        font-weight: 300;
      }
    }

    .body {
      margin-top: 20px;
      border-top: 1px solid #eaeaea;
      max-width: 700px;
    }
  }
}

.Modal.CreateReport {
  .body,
  .content {
    overflow: unset;

    .form-group {
      label {
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
      }
    }
  }

  .ReportSelector {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    li {
      margin: 8px;
      padding: 22px 16px;
      text-align: center;
      border: 2px solid #eaeaea;
      border-radius: 4px;

      img {
        margin-bottom: 16px;
      }

      &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
        box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
      }

      &.active {
        border-color: #2a88f2;
        background-color: #eef5fc;
        -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
        box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
      }
    }
  }
}

.UpdateReportStats {
  .header {
    display: flex;
    align-items: center;

    h3 {
      margin-top: 6px;
    }
    img {
      margin-right: 12px;
    }
  }
  .body {
    width: 600px !important;
    padding: 0 20px !important;
  }
}
