.avatar_text_placeholder {
  margin-top: 10px;
  display: flex;
  align-items: center;

  & .avatar_placeholder {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: $placeholder-color;
  }

  & .text_placeholder {
    margin-left: 8px;
    margin-top: 0;
  }
}

.list_placeholder {
  margin-top: 10px;

  li {
    background-color: $placeholder-color;
    height: 20px;
    width: 40%;
    margin-top: 5px;
  }
}

.text_placeholder {
  height: 20px;
  width: 50%;
  background-color: $placeholder-color;
  margin-top: 15px
}

.experiment_placeholder {
  display: flex;
  align-items: center;

  & .image {
    height: 80px;
    width: 80px;
    background-color: $placeholder-color;
    border-radius: 50%;
  }

  & .text {
    height: 20px;
    width: 40%;
    margin-left: 31px;
    background-color: $placeholder-color;
  }

  & .status {
    flex: 2;
    display: flex;
    justify-content: flex-end;

    & > div {
      width: 80px;
      height: 16px;
      background-color: $placeholder-color;
      margin-right: 14px;
    }
  }
}

.insight_placeholder {
  box-sizing: border-box;
  margin: 15px;
  width: calc(100% - 30px);
  padding: 12px;
  background-color: white;
  height: 140px;
  border-radius: 15px;
  display: flex;
  align-items: center;

  & .text_placeholder {
    margin-left: 5%;
    width: 80%;
  }
}