.adobe {
  &--circle {
    background-image: url(/img/apps/adobe.png);
    background-repeat: no-repeat;
    background-size: 19px;
    width: 25px !important;
    height: 20px !important;
    margin: 5px;
    position: relative;
    display: inline-block;

    & div {
      visibility: hidden;
      width: 120px;
      background-color: #040506;
      color: #fff;
      font-size: 14px;
      margin: 0;
      line-height: 18px;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;

      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;
      top: -95px;
      left: -51px;


      &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: #040506;
        border-width: 6px;
        margin-left: -6px;
      }
    }

    &:hover div {
      visibility: visible;
    }
  }
}