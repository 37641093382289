@import "_vars";
@import "_colors.scss";
@import "trumps/main";
@import "components/main";

.slick-arrow {
  &::before {
    color: #2f4760 !important;
    font-size: 34px;
  }
}

*:focus {
  outline-style: none !important;
  box-shadow: none !important;
}

html {
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  position: relative;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline-style: none;

  #root {
    height: 100%;

    & > .app-body {
      position: absolute;
      width: 100vw;
    }
  }
}

// turn 90 degrees
.nine-deg {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

// Fonts
h1 {
  font-size: 28px;
  font-weight: 300;
  letter-spacing: -1px;
  color: $primary-dark;
}

h2 {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -1px;
  color: $primary-dark;
}

h4 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: $primary-dark;
  margin-bottom: 4px;
}

p {
  color: #757e8e;
  font-size: 14px;
  margin: 0;
  line-height: 18px;

  &.title {
    font-weight: bold;
    color: $primary;
    font-size: 15px;
    margin-bottom: 4px;
  }
}

hr {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #fff;

  &.dash {
    border: 0;
    border-bottom: 1px dashed #eaeaea;
    margin-bottom: 24px;
  }
}

svg {
  fill: #757e8e;
}

ul {
  list-style: none;

  li {
    width: inherit;

    label {
      margin-bottom: 0;
      color: #757e8e;
      margin-right: 4px;
    }
  }
}

.or-splitter {
  margin: 16px 0 4px 0;
  border-top: 1px dashed #eaeaea;
  display: block;
  width: 100%;

  &:before {
    position: relative;
    margin: auto;
    content: "or";
    top: -12px;
    left: 50%;
    background-color: #fff;
    color: #b7c0cc;
    font-weight: bold;
  }
}

.form-group {
  label {
    font-size: 16px;
    text-transform: capitalize;
    color: #71798b;
    font-weight: bold;
    margin-bottom: 1px;
    text-indent: 4px;

    &.normal {
      text-transform: none !important;
    }

    &.required {
      &:after {
        content: "*";
        position: relative;
        top: 2px;
        margin-left: 4px;
        color: $accent-1;
      }
    }
  }
}

.error {
  margin-top: 4px;
  font-size: 14px;
  color: $accent-1;

  &:first-letter {
    text-transform: uppercase;
  }

  &.box {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: $accent-1;
    margin-bottom: 12px;
    color: #fff;
    border-radius: 2px;
  }

  button {
    border: $accent-1 1px solid;

    span.placeholder {
      color: $accent-1;
    }
  }
}

// Forms
input.form-control {
  padding: 24px 14px 24px 14px;
  font-size: 16px;
  border: 2px solid #eaeaea;

  &:focus {
    border-color: $primary;
  }

  &.error {
    border-color: $accent-1;
  }
}

.workspace-input {
  position: relative;

  &:before {
    content: ".illuminate.app";
    display: block;
    position: absolute;
    font-size: 14px;
    color: #757e8e;
    left: 314px;
    top: 16px;
    text-align: right;
    padding-right: 20px;
  }

  input.form-control {
    width: 420px;
  }
}

@media screen and (max-width: 420px) {
  .workspace-input {
    &:before {
      left: 224px !important;
    }

    input.form-control {
      width: 100% !important;
    }
  }
}

textarea.form-control {
  font-size: 14px;
  border: 2px solid #eaeaea;
  min-height: 120px;
  line-height: 18px;
  padding: 8px 12px;
  margin-bottom: 4px;

  &::placeholder {
    color: #b7c0cc;
  }

  &:focus {
    border-color: $primary;
  }

  &.error {
    border-color: $accent-1;
  }
}

button,
a {
  &.btn {
    padding: 14px 14px 14px 14px;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    width: 100%;
    margin-bottom: 12px;

    &.btn-primary {
      background-color: $primary;
    }

    &.btn-primary-light {
      background-color: $primary-light;
      color: $primary;
    }

    &.btn-danger {
      background-color: $accent-1;
      border: 0;

      &:hover {
        background-color: $accent-1;
      }
    }

    &.btn-google {
      background-color: #fff;
      color: $primary;
      border: 1px solid #eaeaea;
      background-image: url("/img/google_logo.svg");
      background-repeat: no-repeat;
      background-position: 22px;

      &:hover {
        border-color: $primary;
      }
    }
  }
}

a {
  color: $primary;
}

.PageLoader,
.PageError {
  width: 100vw;
  height: 100vh;
  display: table;

  div.content {
    display: table-cell !important;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: -40px;

    .logo {
      margin-bottom: 40px;
    }
  }
}

ul.DateRangePicker {
  margin: 0;
  padding: 0;
  display: table;

  li {
    display: table-cell;

    &.seperator {
      display: block;
      padding-right: 20px;
      padding-left: 20px;
      color: #71798b;
      font-weight: 500;
      position: relative;
      top: -6px;
    }

    .react-date-picker.form-control {
      border: 2px solid #eaeaea;
      padding: 0;
      width: 280px;

      .react-date-picker__wrapper {
        padding: 0.375rem 0.75rem;
        border: 0;

        .react-date-picker__inputGroup {
          position: relative;
          top: -5px;

          input {
            color: #495057;
            font-size: 16px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .react-date-picker__calendar-button {
    i.far.fa-calendar {
      position: relative;
      top: -4px;
      left: 8px;
      color: #eaeaea;
    }
  }

  .react-calendar {
    max-width: 280px;
    left: -2px;
    position: relative;
    border: 2px solid #eaeaea;

    .react-calendar__navigation {
      margin-bottom: 0;

      button:hover {
        background-color: #eaeaea;
      }
    }

    .react-calendar__navigation__label {
      font-size: 13px;
    }

    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        font-size: 12px;

        abbr {
          text-decoration: none;
        }
      }
    }

    button.react-calendar__tile {
      font-size: 11px;

      &.react-calendar__tile--active {
        background-color: $primary;
      }
    }
  }
}

div.center {
  margin: 0 auto;
}

.load-logo {
  display: inline-block;
  position: relative;
  width: 110px;
  height: 60px;

  div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #eaeaea;
    border-radius: 40px;
    animation: logo-anim 0.8s cubic-bezier(0, 0.1, 0.1, 1) infinite;
  }

  div:nth-child(1) {
    left: 8px;
    margin-top: 20px;
    height: 20px;
    background: $accent-4-light;
    animation-delay: -0.24s;
  }

  div:nth-child(2) {
    left: 28px;
    height: 40px;
    margin-top: 10px;
    background: $accent-3;
    animation-delay: -0.12s;
  }

  div:nth-child(3) {
    left: 48px;
    height: 60px;
    background: $accent-1;
    margin-top: 0px;
    animation-delay: 0;
  }

  div:nth-child(4) {
    left: 68px;
    height: 40px;
    margin-top: 10px;
    background: $accent-5;
    animation-delay: -0.12s;
  }

  div:nth-child(5) {
    left: 88px;
    height: 20px;
    margin-top: 20px;
    background: $accent-4;
    animation-delay: -0.24s;
  }
}

@keyframes logo-anim {
  0% {
    //top: 8px;
    height: 14px;
  }

  50%,
  100% {
    //top: 24px;
  }
}

/* Customize the label (the container) */
.CheckBox {
  display: block;
  position: relative;
  padding-left: 18px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid #eaeaea;
}

.CheckBox.winner {
  .checkmark {
    border: 2px solid $accent-2;
  }
}

.CheckBox.winner:hover input ~ .checkmark {
  background-color: #fff;
  border: 2px solid $accent-2;
}

.CheckBox.winner input:checked ~ .checkmark {
  background-color: $accent-2;
  border: 2px solid $accent-2;
}

.CheckBox.loss {
  .checkmark {
    border: 2px solid $accent-1;
  }
}

.CheckBox.loss:hover input ~ .checkmark {
  background-color: #fff;
  border: 2px solid $accent-1;
}

.CheckBox.loss input:checked ~ .checkmark {
  background-color: $accent-1;
  border: 2px solid $accent-1;
}

.CheckBox.flat {
  .checkmark {
    border: 2px solid $accent-3;
  }
}

.CheckBox.flat:hover input ~ .checkmark {
  background-color: #fff;
  border: 2px solid $accent-3;
}

.CheckBox.flat input:checked ~ .checkmark {
  background-color: $accent-3;
  border: 2px solid $accent-3;
}

/* On mouse-over, add a grey background color */
.CheckBox:hover input ~ .checkmark {
  background-color: #fff;
  border: 2px solid $primary;
}

/* When the checkbox is checked, add a blue background */
.CheckBox input:checked ~ .checkmark {
  background-color: $primary;
  border: 2px solid $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.CheckBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.CheckBox .checkmark:after {
  left: 4.2px;
  top: 1.2px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-date-picker__calendar {
  position: absolute;
  z-index: 100000;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
