.upload-box {
    margin-top: 12px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .dropzone {
      display: block;
      height: 200px;
      width: 200px;
      margin: 0 8px 8px 0;
      position: relative;
      background-color: #fff;
      overflow: hidden;
      border-radius: 4px;
      border: 4px dashed #eaeaea;
      text-align: center;

      .item-dropbox {
        position: relative;
        height: 200px;
        width: 200px;
        margin: auto;
        top: 38px;
        padding: 12px;

        i {
          color: #f3f3f3;
          font-size: 60px;
          margin-bottom: 8px;
        }

        p {
          font-size: 12px;
          line-height: 12px;
        }
      }

      &.no-images {
        width: 100%;
        background-color: #fff;
        margin: auto;

        .item-dropbox {
          width: 100%;
          p {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }

      &.over {
        border: 4px dashed $accent-2;
        i,
        p {
          color: $accent-2;
        }
      }

      &:hover {
        cursor: pointer;
        border: 4px dashed $primary;
        i,
        p {
          color: $primary;
        }
      }
    }

    .item {
      display: block;
      height: 200px;
      width: 200px;
      margin: 0 12px 12px 0;
      position: relative;
      background-color: #fff;
      overflow: hidden;
      border-radius: 4px;
      border: 2px solid #eaeaea;
      box-shadow: 2px 2px 8px 2px hsla(0, 0%, 80%, 0.47);

      .cntrls {
        display: none;
        position: absolute;
        z-index: 2;
        width: 100%;
        text-align: right;

        .btn.btn-cntrl {
          width: auto;
          margin: 0;

          i {
            color: #eaeaea;
            font-size: 16px;
          }

          &:hover {
            i {
              color: #fff;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;

        &:after {
          transform: scale(2) translateX(0%) translateY(0%) translateZ(0) rotate(-28deg);
        }

        .item-image {
          transform: scale(1.2) translateZ(0);
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          background-color: $primary-dark;
          width: 100%;
          height: 100%;
          opacity: 0.6;
          z-index: 1;
          border-radius: 4px;
        }

        .cntrls {
          display: block;
        }
      }

      &.delete {
        &:before {
          content: '';
          background-color: $primary-dark;
          width: 0;
          height: 0;
          z-index: 0;
        }
      }

      div.delete {
        padding: 14px;
        text-align: center;
        width: 100%;
        height: 100%;
        background-color: $primary-dark;

        &:hover {
          cursor: auto;
        }

        h4 {
          margin-top: 40px;
          color: #fff;
          margin-bottom: 12px;
        }

        .btn {
          padding: 4px;
          max-width: 80%;
          font-size: 14px;
          color: #fff;
        }
      }
    }

    .item-image {
      height: 200px;
      width: 200px;
      backface-visibility: hidden;
      transform: translateZ(0);
      transition: transform 750ms cubic-bezier(0.23, 1, 0.32, 1);

      img {
        position: relative;
        line-height: 0;
        height: 200px;
        width: 200px;
        object-fit: cover;
      }
    }
  }