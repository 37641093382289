.h {
  &--sm {
    height: 24px;
  }

  &-min {
    &__md {
      min-height: 525px;
    }
  }

  &-chart {
    min-height: 450px;
    height: auto;
  }

  &--80 {
    height: 80px;
  }
}