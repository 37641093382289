@import './_mixins.scss';
@import './_colors.scss';

.InsightShare {
  display: block;
  width: 100vw;
  margin: 0 auto;
  height: 100vh;
  background-color: #fff;

  * {
    font-family: 'Roboto', sans-serif;
  }

  h2 {
    font-size: 30px;
  }

  .AnnotateImage {
    .header {
      height: 0;
      margin: 0;
    }

    .img-wrapper {
      padding: 0;

      img {
        width: auto !important;
        align-self: center;
        max-width: 100%;
      }
    }
  }

  ul.ImageIndicator {
    padding-top: 20px;
    text-align: center;

    @include mobile {
      margin-bottom: 0;
      padding-left: 0;
    }

    li {
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #eaeaea;
      border-radius: 50%;
      margin: 0 4px;
    }
  }

  .header {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 0px 30px;
    align-items: center;

    @include mobile {
      margin-bottom: 12px;
    }

    .left {
      img.logo {
        position: relative;
        top: -3px;
      }
    }

    .right {
      margin-left: auto;
      display: flex;

      .dropdown {
        padding: 0;

        .dropdown-button {
          position: relative;
          right: 0;
          top: 12px;
          height: 50px;
          width: 50px;
          border-radius: 100%;
          text-align: center;
          font-size: 18px;
          color: #757e8e;
          padding: 5px 5px 5px 5px;
          border: 0;

          &:hover {
            background-color: #f0f0f0;
            color: #757e8e;
            box-shadow: 0px 0px transparent;

            i {
              color: #757e8e;
            }
          }
        }

        .dropdown-menu {
          top: 10px;
          left: -168px;
        }
      }

      .btn.btn-ctrl {
        position: relative;
        right: 0;
        top: 12px;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        text-align: center;
        font-size: 18px;
        color: #757e8e;
        padding: 5px 5px 5px 5px;

        &:hover {
          background-color: #f0f0f0;
          color: #757e8e;
          box-shadow: 0px 0px transparent;
        }
      }
    }
  }

  .wrapper {
    display: block;
    margin: auto;
    width: 100%;

    section {
      margin-bottom: 20px;

      &.bg-blue {
        background: #f6fafd;
        position: relative;
        z-index: 1;
        margin-top: 60px;
        margin-bottom: 60px;

        &:before {
          top: 0;
          transform: skewY(-1.5deg);
          transform-origin: 0 100%;
        }

        &:before,
        &:after {
          background: inherit;
          content: '';
          display: block;
          height: 50%;
          left: 0;
          position: absolute;
          right: 0;
          z-index: -1;
        }

        &:after {
          top: 60%;
          transform: skewY(1.5deg);
          transform-origin: 100%;
        }
      }

      .content {
        width: 90vw;
        max-width: 1024px;

        @include mobile {
          width: 92vw;
        }

        &.Details {
          display: flex;
          margin: auto;
          margin-top: 50px;
          align-items: center;

          h5, h6, span {
            color: #4a4a4a;
          }

          @include mobile {
            flex-direction: column;
            margin-top: 12px;
          }

          .ExpStatus {
            margin-bottom: 20px;

            @include mobile {
              &.ExpStatus_desktop {
                display: none;
              }
            }

            &.ExpStatus_mobile {
              display: none;
              justify-content: center;
              padding: 8px 0 0;

              @include mobile {
                display: flex;
              }
            }
          }

          .insight {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            div.bar {
              position: relative;
              top: 8px;
              min-height: 30px;
              max-height: 60px;
              width: 4px;
              background-color: $primary;
              margin-right: 60px;

              &:after {
                display: inline-block;
                content: url('/img/quotes.png');
                position: relative;
                left: 20px;
              }
            }

            .text_input {
              width: 100%;
              position: relative;
              top: 8px;

              h1 {
                font-family: 'AmericanTypewriter';
                font-size: 32px;
                word-break: break-word;
                padding: 4px 8px 0px 8px;
              }
            }
          }

          .left {
            padding-right: 40px;
            width: 60%;
            border-right: 1px solid #eaeaea;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 120px;

            @include mobile {
              width: 100%;
              padding: 0;
              border: none;
            }

            /*
             * base style overwrite
             */
            .ExpStatus + h1 {
              @include mobile {
                @include responsiveFontSize(5vw, 28px, 36px);
              }
            }

            h1 {
              color: $primary-dark;
              font-size: 36px;

              @include mobile {
                margin: 0;
                text-align: center;
              }
            }

            p {
              @include mobile {
                text-align: center;
              }
            }
          }

          .right {
            width: 40%;
            max-width: 512px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 120px;

            @include mobile {
              width: 100%;
              padding: 0;
            }

            .targeting {
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;

              @include mobile {
                justify-content: center;
              }

              .target {
                text-align: center;

                @include mobile {
                  width: 30%;
                }

                @include mobile-narrow {
                  width: 33%;
                }

                label {
                  text-transform: uppercase;
                  color: $primary-dark;
                  font-size: 11px;
                  font-weight: 500;

                  @include mobile {
                    margin: 0 0 3px;
                    font-size: 10px;
                  }
                }

                p {
                  text-transform: capitalize;

                  @include mobile {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

        &.Traits {
          margin: auto;
          margin-top: 60px;
          margin-bottom: 60px;

          h2 {
            text-align: center;
            margin-bottom: 20px;
          }

          ul {
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 20px;
              margin: auto;
              margin-bottom: 8px;
              padding: 8px;
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;

              .trait-kind {
                font-size: 18px;
                margin: 0;
              }

              .traits {
                border-radius: 4px;
                border: 2px solid transparent;
                margin: 0;
                padding: 8px 12px 4px 12px;
                list-style: none;
                display: flex;
                flex-direction: row;
                max-width: 100%;
                flex-wrap: wrap;
                min-height: 46px;

                li {
                  padding: 4px 12px 4px 12px;
                  background-color: #757e8e;
                  color: #fff;
                  font-size: 12px;
                  border-radius: 14px;
                  text-transform: capitalize;
                  letter-spacing: 0.2px;
                  margin-right: 4px;
                  white-space: nowrap;
                  margin-bottom: 4px;
                  position: relative;
                  left: -4px;
                }
              }
            }
          }
        }

        &.Experiments {
          margin: auto;
          margin-top: 60px;
          margin-bottom: 60px;

          h2 {
            text-align: center;
            margin-bottom: 20px;
          }

          ul {
            margin: 0;
            padding: 0;

            li {
              margin-bottom: 20px;
              margin: auto;
              margin-bottom: 8px;
              padding: 8px;
              display: flex;
              flex-direction: row;
              align-content: center;
              align-items: center;

              div.insight {
                display: flex;
                padding-top: 4px;
                padding-right: 8px;
                margin: auto;

                a {
                  display: inline-block;
                  font-size: 24px;
                  word-break: break-word;
                  color: $primary;
                  line-height: 26px;
                  padding-right: 20px;
                }
              }
            }
          }
        }

        &.Results {
          display: flex;
          margin: auto;
          margin-top: 40px;
          margin-bottom: 60px;

          @include mobile {
            flex-direction: column;
            margin: 40px auto;
          }

          .left {
            padding-right: 50px;
            width: 50%;

            @include mobile {
              width: 100%;
              margin: 0 auto;
              padding: 0;
            }
          }

          .right {
            align-items: center;
            width: 50%;

            @include mobile {
              width: 100%;
              margin: 0 auto;
              padding: 20px 0 0;
            }

            .img-wrapper {
              border-radius: 4px;
              text-align: center;

              img {
                padding: 6px;
                border: 1px solid #eaeaea;
                max-width: 100%;
                -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
                box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
              }
            }

            .result {
              text-align: center;
              margin-bottom: 8px;

              h2 {
                font-weight: bold;
                margin-bottom: 4px;

                &.positive {
                  color: $accent-2;
                }

                &.negative {
                  color: $accent-1;
                }
              }

              label {
                text-transform: uppercase;
                color: $primary-dark;
                font-size: 11px;
                font-weight: 500;
              }
            }
          }
        }

        &.Experiences {
          margin: auto;
          margin-top: 40px;

          h2 {
            text-align: center;
            margin-bottom: 20px;

            @include mobile {
              margin-bottom: 8px;
              @include responsiveFontSize(4.5vw, 24px, 30px);
            }
          }

          .experience {
            padding: 20px 8px 60px 8px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-around;

            @include mobile {
              flex-direction: column-reverse;
              padding: 12px 0 20px;
            }

            & > div:not(.content) {
              padding-top: 20px;
            }

            .img-wrapper {
              max-width: 472px;
              margin: 0 40px 12px 12px;

              @include mobile {
                width: 84vw;
                margin: 0 auto 12px;
              }

              img {
                width: 100%;
                padding: 6px;
                border: 1px solid #eaeaea;
                border-radius: 4px;
                -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
                box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
              }
            }

            .content {
              width: 50%;

              @include mobile {
                width: 80%;
              }

              @include mobile-narrow {
                width: 100%;
              }

              &:before {
                content: ' ';
                display: table;
                width: 10em;
              }

              .head {
                margin-top: 12px;
                margin-bottom: 20px;

                @include mobile {
                  display: flex;
                  flex-direction: row;
                }

                h4 {
                  display: table-cell;
                  font-weight: 400;
                  font-size: 20px;
                  color: $primary-dark;
                  padding-left: 8px;

                  @include mobile {
                    @include responsiveFontSize(3.5vw, 18px, 20px);
                  }
                }

                .hexagon {
                  display: table-cell;
                  width: 40px;
                  height: 24px;
                  background-color: $accent-2-light;
                  position: relative;

                  @include mobile {
                    top: 2px;
                    display: block;
                    width: 34px;
                    height: 20px;
                  }

                  label {
                    width: 40px;
                    position: absolute;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: #fff;
                    line-height: 25px;
                    font-weight: bold;

                    @include mobile {
                      width: 100%;
                      font-size: 11px;
                      line-height: 22px;
                    }
                  }

                  &:before,
                  &:after {
                    content: ' ';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-right: 20px solid transparent;
                    border-left: 20px solid transparent;

                    @include mobile {
                      border-right: 17px solid transparent;
                      border-left: 17px solid transparent;
                    }
                  }

                  &:before {
                    top: -10px;
                    border-bottom: 10px solid $accent-2-light;

                    @include mobile {
                      top: -9px;
                      border-bottom: 9px solid $accent-2-light;
                    }
                  }

                  &:after {
                    bottom: -10px;
                    border-top: 10px solid $accent-2-light;

                    @include mobile {
                      bottom: -9px;
                      border-top: 9px solid $accent-2-light;
                    }
                  }

                  &.control {
                    background-color: $accent-5;

                    &:before {
                      border-bottom-color: $accent-5;

                      @include mobile {
                        border-bottom-color: $accent-5;
                      }
                    }

                    &:after {
                      border-top-color: $accent-5;

                      @include mobile {
                        border-top-color: $accent-5;
                      }
                    }
                  }
                }
              }

              .questions {
                .question {
                  margin-bottom: 12px;

                  label {
                    text-transform: uppercase;
                    color: $primary-dark;
                    font-size: 12px;
                    font-weight: 500;
                    margin-bottom: 4px;

                    @include mobile {
                      font-size: 11px;
                    }
                  }

                  p {
                    margin-bottom: 12px;
                    word-break: break-word;
                  }

                  ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;

                    li {
                      margin-right: 8px;
                      margin-bottom: 8px;
                      p {
                        display: block;
                        background-color: #757e8e;
                        border-radius: 16px;
                        color: #fff;
                        padding: 4px 16px;
                        text-transform: capitalize;
                        font-weight: 500;
                        font-size: 12px;
                      }
                    }
                  }
                }
              }

              .btn.btn-link {
                width: 100px;
                padding: 0;
                font-size: 13px;
                text-align: left;
                font-weight: normal;
              }
            }

            &:nth-child(odd) {
              flex-direction: row-reverse;

              @include mobile {
                flex-direction: column-reverse;
              }

              .img-wrapper {
                max-width: 472px;
                margin: 0 12px 12px 40px;

                @include mobile {
                  width: 84vw;
                  margin: 0 auto 12px;
                }

                img {
                  padding: 6px;
                  border: 1px solid #eaeaea;
                  border-radius: 4px;
                }
              }
            }
          }
        }

        &.Analysis {
          margin: auto;
          margin-bottom: 60px;

          h2 {
            text-align: center;
            margin-bottom: 20px;
          }

          .ReportSelector {
            label {
              color: #757e8e;
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 500;
              margin: 0;
            }
            .SelectInput {
              width: fit-content;
              min-width: 50%;
            }
          }

          .Report {
            .chart-box {
              text-align: center;
              .header {
                display: block;

                h5 {
                  color: $primary-dark;
                  text-transform: capitalize;
                }
              }
            }
          }

          .ResultsTable {
            margin-top: 30px;

            .ControlColumn {
              li.Column {
                min-width: 220px !important;
              }
            }

            ul.ResultsMetrics {
              left: 220px !important;
              width: calc(100% - 220px) !important;
            }
          }

          .Metric.Control {
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
              background-color: #f6fafd;
            }

            label {
              text-transform: capitalize;
              color: $primary-dark;

              &:hover {
                cursor: pointer;
              }
            }

            i.primary {
              position: absolute;
              left: 12px;
            }

            &.active {
              label {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}