.capitalize {
  text-transform: capitalize;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.align {
  &-left {
    text-align: left;
  }
}

.text {
  &__xsm {
    font-size: 13px !important;
  }

  &__sm {
    font-size: 16px;
  }

  &__md {
    font-size: 20px;
  }

  &__primary {
    color: $primary;
  }

  &__l {
    font-size: 25px;
    font-weight: 500;
  }

  &-did-you-know {
    padding: 0 13px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.2;
  }
}

.line {
  &-height {
    &__md {
      line-height: 1.5;
    }
  }
}

.center {
  text-align: center;
}

.dashboard-title {
  font-size: 18px;
  font-weight: bolder;
}
