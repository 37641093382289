@import "_vars";
@import "_animations";
@import "./_colors.scss";

.App {
  height: inherit;
  background-color: #fbfcfc;

  .Page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: calc(100vh - 61px);
    margin: auto;
    width: 100%;
    background-color: #fbfcfc;

    section.FilterBar {
      min-width: 220px;
      margin-top: 18px;
      max-width: 220px;
      min-height: 100%;
      margin-bottom: 18px;
      border-right: 1px solid #eaeaea;
      max-height: calc(100vh - 61px - 36px);
      overflow-y: scroll;
      overflow-x: hidden;
    }

    section.Aside {
      border-left: 1px solid #eaeaea;
      background-color: #fff;
      position: relative;
      max-height: calc(100vh - 61px);
      overflow-y: scroll;
      overflow-x: visible;
      min-width: 500px;
    }

    section.List {
      margin: 0;
      padding: 20px 14px 40px 14px;
      overflow-y: scroll;
      max-height: calc(100vh - 61px);
      min-width: 546px;
      width: 100%;
    }

    section.Table {
      margin: 0;
      overflow-y: scroll;
      max-height: calc(100vh - 61px);
      min-width: 546px;
      width: 100%;

      table {
        position: relative;
        top: 20px;
      }
    }
  }

  .list-view {
    section.Experiments.List {
      max-height: calc(100vh - 61px - 92px);
    }
    .border {
      margin: 0 15px;
    }
  }

  .Experiment {
    width: 100%;
    margin-bottom: 20px;

    hr {
      border-top: 1px dashed #eaeaea;
      margin: 16px 30px 0 30px;
    }

    .gantt-chart {
      td {
        background: white;
      }
    }

    .tags {
      display: flex;
      padding: 0;

      .tag {
        display: block;
        padding: 4px 12px 4px 12px;
        background-color: #757e8e;
        color: #fff;
        font-size: 12px;
        border-radius: 14px;
        text-transform: capitalize;
        letter-spacing: 0.2px;
        margin-right: 4px;
        white-space: nowrap;
        margin-bottom: 4px;
        position: relative;
        left: -4px;
        width: auto;
        min-width: 80px;

        text-align: center;
        margin-left: 6px;
        margin-top: 4px;
      }
    }

    .MastHead {
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 0 4px;
      height: max-content;

      .btn.uploads {
        position: relative;
        .circle.notifications {
          position: absolute;
          display: flex;
          border-radius: 50%;
          font-size: 11px;
          text-align: center;
          background-color: $primary;
          width: 18px;
          height: 18px;
          align-items: center;
          justify-content: center;
          color: #fff;
          z-index: 1;
          left: 28px;
          top: 20px;
        }
      }
      .body {
        display: table;
        flex-direction: row;
        width: 100%;

        section {
          display: table-cell;
          width: 50%;
          vertical-align: middle;
          height: 62px;

          &:first-child {
            width: 46px;
            padding-left: 24px;
          }

          &:last-child {
            text-align: right;
          }

          &.disabled-tooltip {
            padding-top: 16px;
            text-align: right;
          }

          .TitleGrow {
            height: auto !important;
            min-height: 44px;
          }

          .ExpIcon {
            padding: 0;
            width: 40px;
            height: 40px;
            display: table;
            margin: auto;
            padding-bottom: 4px;
            position: relative;
            top: 4px;

            .text {
              background-color: $primary;
              position: relative;
              border-radius: 50%;
              color: #fff;
              font-weight: 500;
              font-size: 18px;
              text-transform: uppercase;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              font-size: 14px;

              &:before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                left: -5px;
                top: -5px;
                border: 2px solid $primary;
              }
            }
          }
        }

        .btn-ctrl {
          position: relative;
          top: 4px;
          border: 2px solid #eaeaea;
          border-radius: 4px;
          height: 34px;
          width: 40px;
          margin-left: 12px;
          background-color: #fff;

          i {
            color: #eaeaea;
            position: relative;
            top: -10px;
            left: -3px;
          }

          &:hover {
            border-color: #dcdbdb;

            i {
              color: #dcdbdb;
            }
          }
        }

        div.text_input {
          display: block;
          margin: 0 0 4px 12px;
          padding: 4px 8px;
          width: 100%;
          text-align: left;
          height: 44px;

          h2 {
            position: relative;
            top: 2px;
            font-size: 24px;
            font-weight: 500;
            color: $primary-dark;
            margin-bottom: 2px;
            line-height: 26px;
          }

          &.edit {
            padding: 0;
            border-color: transparent;

            input.form-control {
              padding: 20px 14px 20px 14px;
              line-height: 26px;
              height: 44px;
            }
          }
        }

        p.dates {
          font-size: 11px;
          margin-left: 24px;
        }

        div.controls {
          padding: 16px 24px 0 24px;
          width: 100%;
          padding-right: 80px;
          display: table;
          max-width: 240px;
          margin-left: auto;

          div {
            display: table-cell;
            text-align: right;
          }

          .btn.btn-primary {
            display: block;
            padding: 4px 8px 4px 8px;
            margin: 0 16px 0 0;
            white-space: nowrap;
          }

          .dropdown {
            position: relative;
            top: 1px;
            margin: 0;
            padding: 0 0 0 12px;

            button.dropdown-button {
              border: 2px solid #eaeaea;
              border-radius: 4px;
              height: 34px;
              width: 40px;

              i {
                position: relative;
                top: 1px;
              }

              &:hover {
                border-color: #dcdbdb;

                i {
                  color: #dcdbdb;
                }
              }
            }
          }

          .dropdown-menu {
            position: absolute;
            top: -8px;
            left: -100px;
            margin-right: auto;
            z-index: 2;
            min-width: 160px;
          }
        }
      }

      ul.breadCrumbs {
        padding: 16px 24px 0 24px;
        margin: 0;
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;

        li {
          font-size: 12px;
          color: #aeaeae;

          a {
            font-size: 12px;
            margin-right: 4px;
          }

          &::after {
            content: "/";
            font-size: 12px;
            color: #aeaeae;
            margin-right: 4px;
          }

          &:last-child {
            &::after {
              content: "";
              font-size: 12px;
              color: #aeaeae;
            }
          }
        }
      }

      .subNav {
        padding: 8px 24px 0 24px;
        ul {
          list-style: none;
          display: flex;
          padding: 0;
          margin-top: 12px;
          margin-bottom: 0;

          li {
            margin-right: 40px;

            a {
              padding: 0 2px 4px 2px;
              color: #b0bac7;
              text-transform: capitalize;
              font-weight: bold;
              font-size: 14px;
              letter-spacing: 0.6px;
              height: inherit;
              vertical-align: middle;
              display: table-cell;

              &:hover {
                color: $primary;
                text-decoration: none;
              }
            }

            &.active {
              border-bottom: 4px solid $primary;
              a {
                color: $primary;
              }

              border-bottom: 3px saolid $primary;
            }
          }
        }
      }

      &.placeholder {
        h2,
        p {
          background-color: #eaeaea;
          animation: fading 1.5s infinite;
        }

        h2 {
          height: 32px;
          width: 400px;
          margin-bottom: 4px;
        }

        p {
          width: 200px;
          height: 14px;

          .small {
            height: 8px;
          }
        }

        ul.breadCrumbs li {
          p {
            position: relative;
            top: 1px;
            display: inline-block;
            width: 240px;
            margin-right: 4px;
            height: 10px;
          }
        }

        .controls {
          p {
            width: 150px;
            display: inline-block;
            height: 34px;
            margin-right: 4px;
          }

          p:last-child {
            width: 62px;
            display: inline-block;
            height: 34px;
          }
        }

        div.subNav {
          ul li {
            p {
              width: 52px;
              height: 32px;
            }
          }
        }
      }
    }

    .Details {
      width: 100%;
      padding: 20px 0px 20px 26px;

      section {
        display: flex;
        flex-direction: row;
        width: inherit;

        label {
          text-transform: capitalize;
          margin-bottom: 0;
          color: #757e8e;
          margin-right: 4px;
          font-weight: 500;
          font-size: 13px;
        }

        .form-group {
          label,
          p {
            text-indent: 0;
            padding-left: 4px;
          }

          a {
            display: block;
            font-size: 12px;
            padding-left: 4px;
          }

          input.form-control {
            border-width: 1px;
            margin-top: 8px;
            height: 39px;
            padding: 14px 14px 14px 14px;

            &:focus {
              border-color: #eaeaea;
            }
          }
        }

        p {
          font-size: 13px;
        }

        div.grey_box {
          width: 100%;
          padding: 16px;
          border-radius: 4px;
          background-color: #f0f3f4;
        }

        &.General {
          .left {
            min-width: 624px;
            width: 100%;
            margin-right: 80px;
          }

          .right {
            width: 100%;
            min-width: 400px;
            margin-left: auto;
            max-width: 600px;
          }

          ul.DateRangePicker {
            li {
              width: 300px;

              .DatePicker {
                position: relative;
                left: -4px;
                margin-right: 24px;
                width: 280px;
              }

              p {
                padding: 8px 12px 8px 4px;
                margin: 0;
                border: 2px dashed transparent;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }

    .Targeting,
    .Experiences {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 20px 20px 20px 26px;

      &.empty-state {
        width: 100%;
        display: block;
        text-align: center;
        margin: auto;

        p {
          font-size: 12px;
        }

        .btn.btn-primary-light {
          padding: 8px 4px;
          margin-top: 20px;
          max-width: 200px;

          &:hover {
            color: #fff;
            background-color: $primary;
          }

          i {
            position: relative;
            top: -1px;
            font-size: 12px;
            margin-right: 4px;
          }
        }
      }

      label {
        text-transform: none;
        margin-bottom: 0;
        color: #757e8e;
        margin-right: 4px;
        font-weight: 500;
        font-size: 13px;
      }

      h4 {
        font-size: 16px;
        text-transform: capitalize;
        color: #757e8e;
      }

      .left {
        width: 300px;
        margin-right: 80px;

        p {
          font-size: 12px;
        }

        .btn.btn-primary-light {
          padding: 8px 4px;
          margin-top: 20px;

          &:hover {
            color: #fff;
            background-color: $primary;
          }
        }

        .btn i {
          position: relative;
          top: -1px;
          font-size: 12px;
          margin-right: 4px;
        }

        ul.Experiences {
          padding: 0;
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            flex-direction: row;
            margin-bottom: 6px;

            i {
              position: relative;
              top: 4px;
              width: 20px;
              display: inline-block;
              font-size: 12px;

              &.fa-star {
                color: $accent-3;
              }
            }

            button {
              display: inline-block;
              background-color: transparent;
              border: 0;
              text-align: left;
              font-size: 13px;
              width: 100%;
              text-transform: capitalize;

              &:hover {
                color: $primary;
              }
            }

            &.active {
              button {
                font-weight: 500;
              }
            }
          }
        }
      }

      .right {
        width: 100%;
        min-width: 400px;

        .body {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          .form-group {
            min-width: calc(50% - 12px);
            margin-right: 12px;

            label,
            p {
              text-indent: 0;
              padding-left: 4px;
            }
          }
        }

        div.grey_box {
          width: 100%;
          padding: 20px 30px 20px 40px;
          border-left: 4px solid #f0f3f4;

          .form-group {
            label,
            p {
              text-indent: 0;
              padding-left: 4px;
            }

            a {
              display: block;
              font-size: 12px;
              padding-left: 4px;
            }
          }

          .upload-box {
            margin-top: 12px;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .dropzone {
              display: block;
              height: 200px;
              width: 200px;
              margin: 0 8px 8px 0;
              position: relative;
              background-color: #fff;
              overflow: hidden;
              border-radius: 4px;
              border: 4px dashed #eaeaea;
              text-align: center;

              .item-dropbox {
                position: relative;
                height: 200px;
                width: 200px;
                margin: auto;
                top: 38px;
                padding: 12px;

                i {
                  color: #f3f3f3;
                  font-size: 60px;
                  margin-bottom: 8px;
                }

                p {
                  font-size: 12px;
                  line-height: 12px;
                }
              }

              &.no-images {
                width: 100%;
                background-color: #fff;
                margin: auto;

                .item-dropbox {
                  width: 100%;
                  p {
                    font-size: 14px;
                    line-height: 14px;
                  }
                }
              }

              &.over {
                border: 4px dashed $accent-2;
                i,
                p {
                  color: $accent-2;
                }
              }

              &:hover {
                cursor: pointer;
                border: 4px dashed $primary;
                i,
                p {
                  color: $primary;
                }
              }
            }

            .item {
              display: block;
              height: 200px;
              width: 200px;
              margin: 0 12px 12px 0;
              position: relative;
              background-color: #fff;
              overflow: hidden;
              border-radius: 4px;
              border: 2px solid #eaeaea;
              box-shadow: 2px 2px 8px 2px hsla(0, 0%, 80%, 0.47);

              .cntrls {
                display: none;
                position: absolute;
                z-index: 2;
                width: 100%;
                text-align: right;

                .btn.btn-cntrl {
                  width: auto;
                  margin: 0;

                  i {
                    color: #eaeaea;
                    font-size: 16px;
                  }

                  &:hover {
                    i {
                      color: #fff;
                    }
                  }
                }
              }

              &:hover {
                cursor: pointer;

                &:after {
                  transform: scale(2) translateX(0%) translateY(0%)
                    translateZ(0) rotate(-28deg);
                }

                .item-image {
                  transform: scale(1.2) translateZ(0);
                }

                &:before {
                  content: "";
                  position: absolute;
                  display: block;
                  background-color: $primary-dark;
                  width: 100%;
                  height: 100%;
                  opacity: 0.6;
                  z-index: 1;
                  border-radius: 4px;
                }

                .cntrls {
                  display: block;
                }
              }

              &.delete {
                &:before {
                  content: "";
                  background-color: $primary-dark;
                  width: 0;
                  height: 0;
                  z-index: 0;
                }
              }

              div.delete {
                padding: 14px;
                text-align: center;
                width: 100%;
                height: 100%;
                background-color: $primary-dark;

                &:hover {
                  cursor: auto;
                }

                h4 {
                  margin-top: 40px;
                  color: #fff;
                  margin-bottom: 12px;
                }

                .btn {
                  padding: 4px;
                  max-width: 80%;
                  font-size: 14px;
                  color: #fff;
                }
              }
            }

            .item-image {
              height: 200px;
              width: 200px;
              backface-visibility: hidden;
              transform: translateZ(0);
              transition: transform 750ms cubic-bezier(0.23, 1, 0.32, 1);

              img {
                position: relative;
                line-height: 0;
                height: 200px;
                width: 200px;
                object-fit: cover;
              }
            }
          }

          hr {
            margin: 0;
            margin-bottom: 12px;
            padding: 0;
          }
        }
      }
    }

    .Experience {
      .header {
        display: flex;
        width: 100%;
        height: 52px;

        div.form-group {
          min-height: 52px;
          display: flex;
          align-items: center;

          label {
            display: table-cell;
            width: auto;
            vertical-align: middle;
            text-align: left;
            padding-right: 14px;
          }

          .text_input {
            display: table-cell;
            border: 0;
            width: 100%;
            vertical-align: middle;
            text-align: left;
            padding-right: 20px;

            p {
              border: 2px solid transparent;
              &:hover {
                border: 2px dashed #eaeaea;
              }
            }

            input.form-control {
              width: 100%;
              padding: 0.375rem 0.75rem !important;
            }
          }

          &.percent {
            .text_input {
              input.form-control {
                max-width: 100px;
              }
            }
          }
        }

        .controls {
          margin-left: auto;

          .dropdown-menu {
            left: -120px;
            top: -8px;
            min-width: 160px;
          }
        }
      }
    }

    .Results {
      width: 100%;
      padding: 20px 20px 20px 26px;

      &.empty-state {
        text-align: center;
        margin-top: 100px;

        .wrapper {
          margin: auto;
          max-width: 480px;

          img {
            margin-bottom: 22px;
          }

          .btn.btn-primary {
            margin-top: 12px;
            max-width: 200px;
            padding: 8px 12px;
          }
        }
      }

      section {
        display: flex;
        flex-direction: row;
        width: inherit;
        margin-bottom: 20px;

        label {
          text-transform: capitalize;
          margin-bottom: 0;
          color: #757e8e;
          margin-right: 4px;
          font-weight: 500;
          font-size: 13px;
        }

        &.Header {
          display: table;
          border-bottom: 1px solid #eaeaea;

          .left,
          .right {
            width: 50%;
            display: table-cell;
            vertical-align: middle;
            padding: 0 8px;
          }

          .btn.btn-primary {
            padding: 8px;
            width: 150px;
            font-size: 14px;

            i {
              margin-right: 4px;
            }
          }

          .form-group {
            display: table;
            width: 100%;

            label {
              width: 100px;
              display: table-cell;
              padding-right: 8px;
            }

            .SelectInput {
              width: 100%;
            }
          }
        }

        &.Report {
          display: block;
          margin-top: 20px;
          padding: 0 8px;

          .header {
            display: flex;
            width: 100%;
            margin-bottom: 8px;

            .left {
              width: 100%;

              vertical-align: middle;
              padding: 0 8px;

              h4 {
                font-size: 16px;
                color: #757e8e;
                margin: 0;
              }

              label {
                color: #aeaeae;
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 0.4px;
              }
            }

            .right {
              margin-left: auto;
              margin-right: 14px;
              display: flex;
              align-items: center;
              width: auto;

              .dropdown {
                right: 0;

                .dropdown-menu {
                  position: absolute;
                  top: -8px;
                  left: -174px;
                  margin-right: auto;
                  z-index: 2;
                }
              }

              div.date {
                display: flex;
                flex-wrap: nowrap;
                margin-right: 40px;
                align-items: center;

                label {
                  color: #757e8e;
                  white-space: nowrap;
                  text-transform: uppercase;
                  letter-spacing: 0.2px;
                  font-size: 11px;
                }

                p {
                  font-size: 11px;
                  text-transform: capitalize;
                  white-space: nowrap;
                }
              }
            }
          }

          .chart-box {
            display: block;
            background-color: #fff;
            width: 100%;
            padding: 20px;
            text-align: center;
            border: 1px solid #eaeaea;
            //min-height: 200px;
            border-radius: 2px;
            margin-bottom: 20px;

            .header {
              h5 {
                text-transform: capitalize;
                color: $primary-dark;
                margin: auto;
              }
            }

            .body {
              display: block;
              width: 100%;

              p.placeholder {
                color: #aeaeae;
              }
            }
          }

          .data-table {
            display: flex;
            background-color: #fff;
            width: 100%;
            border: 1px solid #eaeaea;

            .table {
              display: table;

              .header {
                display: table-header-group;

                div {
                  display: table-cell;
                  max-width: 320px;
                  min-width: 220px;
                  text-align: center;
                  padding: 20px;
                  color: $primary-dark;
                  font-size: 12px;
                  letter-spacing: 0.2px;
                  border-right: 1px solid #eaeaea;
                  font-weight: bold;

                  &:nth-child(even) {
                    background-color: #f6fafd;
                  }
                }
              }
            }

            .table-wrapper {
              //overflow: auto;
              width: 100%;

              table {
                display: table;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;
                overflow: visible;

                thead {
                  tr th {
                    max-width: 320px;
                    min-width: 300px;
                    text-align: center;
                    padding: 20px;
                    color: $primary-dark;
                    font-size: 12px;
                    letter-spacing: 0.2px;
                    border-right: 1px solid #eaeaea;

                    &:nth-child(even) {
                      background-color: #f6fafd;
                    }

                    &.sticky-col {
                      position: sticky;
                      position: -webkit-sticky;
                      left: 0;
                      background-color: #fff;
                      border-right: 1px solid #eaeaea;
                      border-collapse: separate;
                    }
                  }
                }

                tbody {
                  tr.header {
                    display: table-row;
                    background-color: #fcfcfc;

                    td {
                      text-transform: uppercase;
                      font-size: 10px;
                      font-weight: bold;
                      text-align: center;
                      color: #757e8e;
                      padding: 10px;
                      letter-spacing: 0.2px;
                      border-right: 1px solid #eaeaea;
                      border-bottom: 1px solid #eaeaea;
                      min-width: 100px;

                      &.no-br {
                        border-right: 1px solid transparent;
                      }

                      &.sticky-col {
                        position: sticky;
                        position: -webkit-sticky;
                        left: 0px;
                        background-color: #fcfcfc;
                        border-right: 1px solid #eaeaea;
                      }
                    }
                  }

                  tr.data-row {
                    display: table-row;
                    background-color: #fcfcfc;

                    td {
                      display: table-cell;
                      font-size: 12px;
                      padding: 0px;
                      height: 40px;
                      border-right: 1px solid #eaeaea;
                      border-bottom: 1px solid #eaeaea;
                      background-color: #fff;
                      vertical-align: middle;

                      .metric-ctrl {
                        display: flex;
                        padding: 0px 8px;

                        &:hover {
                          cursor: pointer;
                        }

                        .dropdown {
                          top: -2px;
                        }

                        .metric {
                          width: 100%;
                          position: relative;

                          &.placeholder {
                            color: #757e8e;
                          }

                          &:hover {
                            color: $primary;

                            &:after {
                              font-family: "Font Awesome 5 Free";
                              content: "\f078";
                              color: #757e8e;
                              font-style: normal;
                              font-weight: 900;
                              float: right;
                              margin-right: 8px;
                            }
                          }

                          // .SelectInput {
                          //   position: static;

                          //   .menu {
                          //     position: absolute;
                          //     z-index: 2;
                          //   }
                          // }
                        }

                        i.fa {
                          text-align: center;
                          width: 20px;
                          position: relative;
                          float: right;
                          margin-right: 8px;
                          margin-left: 4px;
                          top: 4px;

                          &.fa-star {
                            color: $accent-3;
                          }
                        }
                      }

                      input {
                        width: 100%;
                        height: 40px;
                        border: 0;
                        padding: 8px;
                        text-align: center;
                        border: 1px solid transparent;

                        &:focus {
                          border: 1px solid $primary;
                        }
                      }

                      &.sticky-col {
                        position: sticky;
                        position: -webkit-sticky;
                        left: 0px;
                        background-color: #fff;
                        border-right: 1px solid #eaeaea;
                      }
                    }
                  }
                }

                tfoot {
                  tr td {
                    padding: 10px;
                    font-size: 12px;
                    text-indent: 8px;

                    &.sticky-col {
                      position: sticky;
                      position: -webkit-sticky;
                      left: 0px;
                      background-color: #fff;
                    }

                    .btn.btn-link {
                      width: unset;
                      font-size: 12px;
                      text-align: left;
                      padding: 0;
                      margin: 0;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .Settings {
    width: 100%;
    margin-top: 40px;

    .subNav {
      ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin-bottom: 30px;

        li {
          margin-right: 60px;

          a {
            padding: 0 2px 4px 2px;
            color: #b0bac7;
            text-transform: capitalize;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            height: inherit;
            vertical-align: middle;
            display: table-cell;

            &:hover {
              color: $primary;
              text-decoration: none;
            }
          }

          &.active {
            border-bottom: 4px solid $primary;
            a {
              color: $primary;
            }

            border-bottom: 3px saolid $primary;
          }
        }
      }
    }

    .header {
      margin-bottom: 0px;

      button.btn {
        float: right;
        width: auto;
        padding: 4px 20px;
        position: relative;
      }
    }

    section {
      margin: 30px 0 30px 0;
    }

    ul.h-list {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;

      li {
        margin-right: 100px;

        label {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 4px;
          text-transform: uppercase;
          font-size: 10px;
          letter-spacing: 0.8px;
          white-space: nowrap;
        }

        p {
          white-space: nowrap;
          color: $primary-dark;

          &.text-lrg {
            font-size: 14px;
          }
        }
      }
    }

    hr.dashed {
      border: 0px;
      border-top: 1px dashed #d1d2d1;
      max-width: 100%;
    }

    .AppSettings {
      display: flex;
      flex-direction: row;

      .desc {
        width: 300px;
        margin-right: 30px;

        h4 {
          font-size: 14px;
          font-weight: 400;
        }

        p {
          font-size: 11px;
        }
      }

      .controls {
        .control {
          display: table;
          margin-bottom: 16px;

          .desc {
            display: table-cell;
            width: 100%;
            padding: 4px 20px 4px 4px;

            label {
              font-weight: bold;
              font-size: 14px;
              margin-bottom: 2px;
              color: #71798b;
            }

            p {
              font-size: 11px;
              line-height: 14px;
            }
          }

          .toggle {
            display: table-cell;
            width: auto;
            max-width: 100px;
            padding: 4px;
            vertical-align: middle;
            text-align: center;
            padding: 4px 20px 4px 4px;
          }
        }

        .password-form {
          justify-content: space-between;
          .password-field {
            flex-basis: 75%;
            position: relative;

            .error {
              margin-top: 0;
            }
            button {
              position: absolute;
              right: 40px;
              top: 0;
              font-size: 20px;
              width: 30px;
              color: #eaeaea;
            }
          }
          button {
            flex-basis: 20%;
            padding: 13px 14px;
            max-height: 52px;
          }
        }
      }
    }

    .InviteList {
      .control {
        .dropdown-menu {
          top: 0px !important;
          left: -95px !important;
          min-width: 160px;
        }
      }
    }

    .InviteList,
    .UserList {
      padding: 0 0 10px;
      max-height: calc(100vh - 250px);
      overflow: auto;

      li {
        border: 1px solid #eaeaea;
        background-color: #fff;
        margin-bottom: 12px;
        padding: 0px;
        display: table;
        width: 100%;
        border-radius: 2px;

        &:hover {
          cursor: pointer;
          -webkit-box-shadow: 2px 2px 6px #eaeaea;
          -moz-box-shadow: 2px 2px 6px #eaeaea;
          box-shadow: 2px 2px 6px #eaeaea;
        }

        div {
          padding: 8px;
          display: table-cell;
          vertical-align: middle;

          &.Avatar {
            position: relative;
            display: table-cell;
            width: 30px;
            margin-right: 20px;
            vertical-align: middle;
            top: 3px;

            .user-name {
              display: none !important;
            }

            &:hover {
              .user-name {
                display: block !important;
                background-color: #757e8e;
                padding: 4px 12px 4px 12px;
                color: #fff;
                font-size: 12px;
                position: absolute;
                border-radius: 14px;
                text-transform: capitalize;
                white-space: nowrap;
                z-index: 1000;
              }
            }

            .user {
              display: block;
              min-width: 44px;
              max-width: 44px;
              height: 44px;
              color: #818a98;
              font-size: 16px;
              line-height: 30px;
              text-indent: 1px;

              img {
                min-width: 44px;
                max-width: 44px;
                height: 44px;
                position: relative;
                top: -8px;
                left: -8px;
              }

              &:before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                left: -3px;
                top: -3px;
                border: 1px solid #eaeaea;
              }
            }
          }

          &.Icon {
            position: relative;
            display: table-cell;
            min-width: 80px;
            margin-right: 20px;
            vertical-align: middle;
            text-align: center;

            i {
              font-size: 24px;
              color: $primary;
            }
          }

          h4 {
            font-size: 14px;
            margin: 0;
          }

          div.role {
            display: block;
            padding: 4px 16px 4px 16px;
            width: 100%;
            border-radius: 20px;
            background-color: #757e8e;
            color: #fff;
            font-size: 12px;
            text-transform: capitalize;

            &.read-only {
              min-width: 92px;
            }
          }

          div.control {
            padding-right: 20px;
            min-width: 74px;

            .dropdown-menu.show {
              position: absolute;
              top: -18px;
              left: -160px;
              z-index: 2;
            }
          }
        }
      }
    }

    .Integrations {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .box {
        padding: 14px;
        border: 1px solid #eaeaea;
        margin-right: 12px;
        background-color: #fff;
        border-radius: 4px;
        text-align: center;
        width: 250px;
        height: 200px;

        &:hover {
          cursor: pointer;
          -webkit-box-shadow: 2px 2px 6px #eaeaea;
          -moz-box-shadow: 2px 2px 6px #eaeaea;
          box-shadow: 2px 2px 6px #eaeaea;

          img {
            opacity: 1;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .title {
          position: relative;
          font-size: 16px;
          color: $primary-dark;
          font-weight: 500;
          top: 48px;
        }

        img {
          position: relative;
          top: 38px;
          opacity: 0.4;
        }

        &.optimizely {
          .title {
            top: 54px;
          }
        }

        &.enabled {
          img {
            opacity: 1;
          }

          &:after {
            content: "\f00c";
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-weight: 900;
            position: relative;
            top: -130px;
            left: -120px;
            background-color: $accent-2;
            color: #fff;
            padding: 12px;
            border-radius: 50%;
          }
        }
      }
    }
    .add-goal {
      width: 220px;
      button {
        padding: 8px 14px;
      }
    }
  }
}

.Public {
  height: inherit;

  .Page {
    height: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;

    .content {
      height: inherit;
      width: 100%;
      padding: 60px 20px 60px 60px;
      margin: 0;
      min-width: 500px;
      display: flex;
      flex-direction: column;

      .logo {
        position: relative;
        margin-bottom: 40px;
      }

      .form {
        position: relative;
        display: block;
        width: 100%;
        max-width: 600px;
        margin: auto 0;
        padding-bottom: 60px;
        z-index: 1;

        .form-group {
          p.desc {
            margin-top: 4px;
          }
        }
      }
    }

    .aside {
      top: 0;
      right: 0;
      height: 100vh;

      img {
        object-fit: cover;
        height: 100%;
      }

      &.signup {
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;

        img {
          margin-top: 40px;
          object-fit: none;
          height: auto;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .Public {
    .content {
      min-width: 100% !important;
      text-align: center;
      padding: 60px 40px 60px 40px !important;

      .or-splitter:before {
        left: unset !important;
      }

      .form-group {
        text-align: left;
      }
    }

    .aside {
      display: none;
    }
  }
}

// List Item
ul.ListItem {
  list-style: none;
  display: table;
  table-layout: fixed;
  margin: 0;

  padding: 20px 0 40px 0;
  max-height: calc(100vh - 85px);
  width: 100%;

  li {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    margin-bottom: 20px;
    display: table;
    width: calc(100% - 30px);
    margin: auto;
    margin-bottom: 8px;
    transition: all 400ms;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      width: calc(100% - 0px);
      -webkit-box-shadow: 2px 2px 6px #eaeaea !important;
      -moz-box-shadow: 2px 2px 6px #eaeaea !important;
      box-shadow: 6px 6px 6px #eaeaea !important;
    }

    div.left,
    div.right,
    div.body {
      padding: 0 12px 0 12px;
      display: table-cell;
      height: 100%;
      vertical-align: middle;

      ul.traits {
        margin: 0;
        margin-top: 8px;
        background-color: transparent;
        padding: 0;

        li {
          padding: 0;
          border: 0;
          margin: 0;
          background-color: transparent;
          vertical-align: middle;

          label {
            display: inline-block;
            font-weight: 400;
            font-size: 12px;
            margin: 0 4px 2px 0;
            text-transform: capitalize;
            letter-spacing: 0.8px;
            white-space: nowrap;
            color: #aaaaaa;
          }

          p {
            display: inline-block;
            margin-top: 0 !important;
            margin-right: 4px;
            font-size: 12px;
            color: $primary-dark;
            padding: 0;
            top: 1px;
            position: relative;
            text-transform: capitalize;

            &:after {
              content: ",";
            }

            &:last-child {
              &:after {
                content: "";
              }
            }
          }
        }
      }

      h4.title {
        font-size: 15px;
        margin: 8px 0;
      }

      p.desc {
        font-size: 14px;
        line-height: 18px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 800px;
      }

      p.date {
        font-size: 10px;
      }
    }

    .body {
      width: 100%;
    }

    .left,
    .right {
      text-align: center;
      min-width: 80px;
    }

    i.fa-star {
      color: #eaeaea;

      &.active {
        color: $accent-3-dark;
      }
    }

    .score {
      text-align: center;
      padding-right: 16px;

      h3 {
        font-weight: 300;
        margin: 0;
      }

      p {
        text-transform: capitalize;
        margin: 0;
        font-size: 12px;
      }
    }
  }

  &.placeholder {
    h4,
    p,
    .status {
      background-color: #eaeaea;
      animation: fading 1.5s infinite;
    }

    h4 {
      width: 80%;
      height: 18px;
      margin: 12px 0;
    }

    p {
      height: 14px;
      margin-bottom: 2px;
    }

    .body {
      ul {
        width: 100%;

        li {
          p {
            background-color: #eaeaea;
            animation: fading 1.5s infinite;
            height: 14px;
            margin-bottom: 2px;
            min-width: 100%;
          }
        }
      }
    }

    .right,
    .status {
      p {
        height: 8px;
      }
    }

    .left {
      .Thumbnail {
        .image {
          background-color: #eaeaea;
          animation: fading 1.5s infinite;
        }
      }
    }
  }
}

.Dashboard {
  width: 100%;

  hr {
    display: block;
    margin: 0 30px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #fff;
  }

  section {
    width: 100%;
    padding: 20px 30px 20px 30px;

    &.header {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;

      div {
        h2 {
          position: relative;
          top: 12px;
          font-size: 26px;
          font-weight: 300;
        }

        &.ctrls {
          display: flex;
          margin-left: auto;

          .SelectInput {
            position: relative;
            right: 0;
            width: 300px;
            margin-left: 12px;
          }
        }
      }
    }

    &.stats {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;

      .totals {
        display: flex;
        flex-direction: column;
        margin-right: 80px;
        min-width: 180px;

        .total {
          margin-bottom: 16px;

          label {
            color: $primary-dark;
            text-transform: capitalize;
            margin: 0;
          }

          &.selector {
            position: relative;

            label {
              color: $primary-dark;
              text-transform: capitalize;
              margin: 0;
              font-size: 18px;
              white-space: nowrap;

              &:hover {
                color: $primary;
                cursor: pointer;
              }

              &:after {
                font-family: "Font Awesome 5 Free";
                content: "\f078";

                font-style: normal;
                font-weight: 900;
                font-size: 12px;
                margin-left: 12px;
              }
            }

            .menu {
              visibility: hidden;
              border-radius: 2px;
              min-width: 225px;
              border: 1px solid #eaeaea;
              box-shadow: -4px 2px 8px #b7c0cc;
              background-color: #fff;
              padding: 0;
              position: absolute;
              z-index: 2;
              top: 0;
              left: -8px;
              border-radius: 4px;

              &.show {
                visibility: visible;
                -webkit-animation: swing-in-top-fwd 0.5s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
                animation: swing-in-top-fwd 0.5s
                  cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
              }

              .header {
                border-bottom: 1px solid #eaeaea !important;
                padding: 4px 8px;
                label {
                  text-transform: uppercase;
                  font-size: 11px;
                  font-weight: 500;
                  color: #bac3ce;
                  margin: 0;
                  letter-spacing: 0.2px;

                  &:after {
                    content: "";
                  }
                }
              }

              ul {
                padding: 0;
                margin: 0;

                li {
                  border: 0 !important;
                  font-size: 14px;
                  font-weight: normal;
                  margin: 0 !important;
                  text-transform: capitalize;
                  font-weight: 500;
                  padding: 8px;
                  border-bottom: 1px solid #eaeaea !important;
                  color: $primary-dark;

                  &:hover {
                    cursor: pointer;
                    color: $primary;
                    background-color: #fbfcfc;
                  }

                  &:last-child {
                    border-bottom: 1px solid transparent !important;
                  }
                }
              }
            }
          }

          .values {
            display: flex;
            flex-direction: row;

            div {
              margin-left: 20px;

              p.dec {
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                color: #bac3ce;
                margin: 0;
                letter-spacing: 0.2px;
              }

              h3 {
                color: $primary-dark;
                font-weight: 300;
                font-size: 32px;
                margin: 0;
                white-space: nowrap;
              }

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }

      .pieChart {
        display: block;
        margin: 20px;
        position: relative;
        min-width: 240px;
        margin-right: 80px;

        svg {
          height: 240px;
        }

        div.label {
          height: auto;
          position: absolute;
          width: 100%;
          top: 30%;
          margin: auto;
          z-index: 1;

          .val {
            font-size: 54px;
            font-weight: 500;
            text-align: center;
            padding-left: 8px;
            color: $primary-dark;

            span {
              font-size: 20px;
              font-weight: 300;
            }
          }

          label {
            width: 100%;
            text-align: center;
            color: $primary-dark;
            margin: auto;
            text-transform: uppercase;
            font-size: 10px;
            position: relative;
            top: -16px;
            letter-spacing: 0.2px;
            font-weight: bold;
          }
        }
      }

      .lineChart {
        position: relative;
        display: block;
        width: 100%;
        height: 240px;

        label {
          color: $primary-dark;
          font-weight: 500;
          text-transform: capitalize;
          margin: 0;
          margin-left: 16px;
        }

        p.dec {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 500;
          color: #bac3ce;
          margin: 0;
          letter-spacing: 0.2px;
          margin-left: 16px;
        }

        .BarChart {
          margin-top: 20px;
        }
      }
    }

    &.targeting {
      label {
        color: $primary-dark;
        text-transform: capitalize;
        margin: 0;
      }

      p.dec {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        color: #bac3ce;
        margin: 0;
        letter-spacing: 0.2px;
      }

      .traits {
        display: flex;
        width: 100%;
        margin-top: 12px;

        .trait {
          width: 100%;
          margin-right: 28px;
          flex: 3;

          label {
            color: $primary-dark;
            text-transform: capitalize;
            margin: 0;
            margin-bottom: 4px;
            font-size: 14px;
          }

          .body {
            background-color: #fff;
            // border: 1px solid #eaeaea;
            padding: 16px 12px;
            border-radius: 2px;

            ul {
              padding: 0;

              li {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                div.header {
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  color: $primary-dark;

                  div:last-child {
                    margin-left: auto;
                    font-weight: 500;
                    font-size: 14px;
                    small {
                      position: relative;
                      top: -2px;
                      margin-right: 8px;
                      color: #757e8e;
                    }
                  }
                }

                div.progBar {
                  width: 100%;
                  height: 8px;
                  border-radius: 6px;
                  background-color: #eaeaea;

                  div.fill {
                    background-color: $primary;
                    height: 9px;
                    display: inline-block;
                    position: relative;
                    top: -8px;
                    z-index: 1;
                    border-radius: 6px;
                  }
                }
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.Experiments,
.Ideas,
.Insights {
  width: 100%;
  padding: 20px 20px 20px 26px;

  &.empty-state {
    text-align: center;
    margin-top: 100px;

    h4 {
      font-weight: 300;
      margin: 0 0 8px 0;
    }

    .wrapper {
      margin: auto;
      max-width: 600px;

      img {
        margin-bottom: 22px;
      }

      .btn.btn-primary,
      .btn.btn-primary-light {
        margin-top: 12px;
        max-width: 200px;
        padding: 8px 12px;
      }
    }
  }
}

.Tasks {
  width: 100%;
  padding: 50px 20%;

  .title {
    font-weight: bold;
    color: $primary-dark;
  }

  .desc {
    font-size: 12px;
    color: grey;
  }
}

.Calculator {
  .title {
    font-size: 24px;
  }

  hr {
    border: 1px #f0f3f4 dashed;
  }

  .form-group {
    display: flex;

    input[type="text"] {
      width: 140px;
      font-size: 28px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 14px;
      font-weight: bold;
      color: grey;
    }
    p {
      font-size: 12px;
    }
    .submit {
      width: 40%;
    }
    .form-control {
      text-align: center;
    }
    .relative {
      position: relative;
      p {
        position: absolute;
        top: 20px;
        right: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #222;
      }
    }
  }
  hr.boundary {
    width: 180%;
  }
  .btn.btn-primary {
    padding: 4px;
  }
}
button:disabled {
  cursor: not-allowed;
}

button.link-basic {
  color: $primary;
  cursor: pointer;
  &:hover {
    color: $primary;
    text-decoration-line: underline;
    text-decoration-style: solid;
  }
}

.pad20 {
  padding: 0 20px;
}
.save-goal {
  justify-content: flex-end;
  button.btn {
    width: 150px;
    padding: 8px 14px;
  }
}

.ShareLogin {
  padding: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .body {
    padding: 20px;
    width: 100%;
    text-align: center;
    max-width: 600px;

    h3 {
      font-weight: 300;
      margin: 40px 0 12px 0;
      color: $primary-dark;
      text-align: center;
    }

    img {
      margin: auto;
    }

    form {
      margin-top: 20px;
    }
  }
}

.Terms,
.Privacy {
  .leftNav {
    position: fixed;
    background-color: transparent;
    width: 220px;
    height: 300px;
    padding: 80px 0;
    -webkit-box-shadow: 20px 0 30px -30px #eaeaea;
    -moz-box-shadow: 20px 0 30px -30px #eaeaea;
    box-shadow: 20px 0 30px -30px #eaeaea;

    div.selected {
      border-right: 6px $primary solid;

      a {
        color: $primary;
      }
    }

    div {
      display: flex;
      align-items: center;
      height: 30px;
      text-indent: 30px;
      font-size: 14px;
      margin-bottom: 8px;

      a {
        color: $primary-dark;
      }
    }
  }

  .body {
    position: relative;
    margin-left: 220px;
    padding: 0 60px 60px 60px;

    h1 {
      font-size: 40px;
      font-weight: 200;
      margin-bottom: 20px;
    }

    h6 {
      font-weight: 500;
      color: $primary-dark;
    }
  }
}

.Terms {
  p {
    color: $primary-dark;
    margin-bottom: 22px;
    font-weight: normal;
  }

  .body {
    padding-right: 50px;
  }

  .space-between {
    justify-content: space-between;
  }

  .justify {
    text-align: justify;
  }

  .center {
    text-align: center;
  }

  .underline {
    text-decoration: underline;
  }

  .preface {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
  }

  ol.alpha {
    list-style-type: lower-alpha;
    padding-left: 50px;
  }

  ol {
    text-align: justify;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding-left: 15px;

    // li {
    //   counter-increment: list;
    //   list-style-type: none;
    //   position: relative;

    //   &:before {
    //     color: $primary-dark;
    //     content: counter(list) '.';
    //     left: -32px;
    //     position: absolute;
    //     text-align: right;
    //     width: 26px;
    //   }
    // }
  }
}

.Privacy {
  p {
    color: $primary-dark;
    margin-bottom: 22px;
  }

  div {
    margin-bottom: 20px;
    line-height: 2;
    font-size: 16px;
  }

  h6 {
    font-weight: bold;
  }

  .full-width * {
    width: 100%;
  }

  .preface {
    font-size: 14px;
    margin-bottom: 20px;
  }

  div.italic {
    font-style: italic;
  }

  div.address p {
    margin-bottom: 6px;
  }

  ul {
    margin-top: 20px;
    list-style: disc;

    li {
      margin-bottom: 12px;
    }
  }

  .leftNav div {
    margin: 0;
  }
}
