.switch_button {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 8px;

  & button {
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
    width: auto;
    padding: 5px 11px;
    text-align: center;
    vertical-align: middle;
    border-top: 1px solid #2a88f2;
    border-bottom: 1px solid #2a88f2;
    border-left: 1px solid #a79fe8;
    border-right: 0px;
    background-color: transparent;
    font-size: 14px;
    color: #2f4760;

    &.active {
      color: white !important;
      background-color: #2a88f2 !important;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-left: 1px solid #2a88f2;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-right: 1px solid #2a88f2;
    }
  }
}