@import "_vars";
@import "_colors.scss";

.FullScreen {
  display: block;
  min-width: 100vw;
  min-height: 100vh;

  div.logo {
    position: absolute;
    top: 24px;
    left: 30px;
  }

  .header {
    .ctrls {
      display: flex;
      margin-left: auto;
      align-items: center;

      a.btn-back {
        margin-right: 20px;

        i {
          margin-right: 8px;
        }
      }
    }
  }

  ul.options {
    position: absolute;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    top: 20px;
    right: 200px;
    z-index: 1;

    li {
      margin: 8px;

      label {
        text-transform: uppercase;
        font-size: 11px;

        &:hover {
          color: $primary;
          cursor: pointer;
        }

        &.active {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  ul.controls {
    position: absolute;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    top: 20px;
    right: 30px;
    z-index: 2;

    li {
      margin-left: 6px;

      .btn.btn-ctrl {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        text-align: center;
        font-size: 18px;
        color: #757e8e;
        padding: 4px;
        border: 1px solid #eaeaea;
        margin: 0;
        background-color: #fff;

        &:hover {
          background-color: #eaeaea;
          color: #757e8e;
          box-shadow: 0px 0px transparent;
        }

        i {
          position: relative;
          left: 1px;

          &.fa-crosshairs {
            left: 0;
          }

          &.fa-chevron-left {
            left: -1px;
          }
        }

        &.enabled {
          color: $accent-1;
          border: 1px solid $accent-1;
          background-color: $accent-1-light;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    margin: 0px 40px 20px 30px;

    img.logo {
      align-self: center;
      position: relative;
      top: -1px;
    }

    .btn.btn-close {
      position: relative;
      top: 12px;
      margin-left: auto;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      text-align: center;
      font-size: 18px;
      color: #757e8e;
      padding: 8px 5px 5px 5px;

      &:after {
        display: block;
        content: "esc";
        font-size: 8px;
        position: relative;
        top: -8px;
        margin-top: 4px;
        text-transform: uppercase;
      }

      &:hover {
        background-color: #eaeaea;
        color: #757e8e;
        box-shadow: 0px 0px transparent;
      }
    }
  }

  form {
    margin-top: 20px;
  }

  .form-body {
    margin: 0 40px;

    .content {
      margin: auto;
      max-width: 800px;
      position: relative;

      div.title {
        width: 100%;

        div.controls {
          position: absolute;
          right: 0;
          top: 12px;
          margin-right: 46px;

          .btn.btn-link {
            width: auto;
            padding: 0;
            margin: 0;
            font-size: 13px;
            text-decoration: none;

            i {
              margin-right: 8px;
            }
          }
        }
      }

      button.btn.btn-primary {
        width: auto;
        padding: 8px 40px;
        margin-left: 46px;
      }

      section {
        margin: 16px 0;

        .title {
          margin-bottom: 12px;

          h4 {
            display: inline-block;
            font-size: 18px;
            text-transform: capitalize;
            color: $primary-dark;
          }

          p {
            margin-left: 46px;
            color: #757e8e;
            font-size: 13px;
          }

          .step-circle {
            margin-right: 16px;
            display: inline-block;
            height: 30px;
            width: 30px;
            color: #fff;
            background-color: $primary;
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
            font-weight: 500;
            font-size: 14px;
          }
        }

        .body {
          height: auto;
          margin: 0 46px;
          display: block;

          label {
            color: #71798b;
            font-size: 14px;
            letter-spacing: 0.2px;
            margin-bottom: 6px;
            text-transform: none;

            &:first-letter {
              text-transform: uppercase;
            }
          }

          p {
            margin-top: 8px;
            font-size: 13px;
          }

          .btn.btn-link {
            width: auto;
            margin: 0;
            padding: 4px 0px;

            i {
              margin-right: 4px;
              font-size: 12px;
              position: relative;
              top: -2px;
            }
          }

          table {
            width: 100%;

            thead {
              tr {
                th {
                  padding-left: 20px;

                  label {
                    text-transform: capitalize;
                  }

                  &:first-child {
                    padding-left: 0;
                  }
                }

                &:first-child {
                  th:first-child {
                    padding-left: 0;
                  }
                }
              }
            }

            &.experiences {
              tbody tr {
                td {
                  padding-left: 20px;
                  padding-bottom: 8px;
                  width: 100%;

                  div.input {
                    display: block;
                    width: 100%;
                    border: 2px solid #eaeaea;
                    border-radius: 0.25rem;

                    input {
                      width: 100%;
                      border: 0;
                    }

                    &.percent {
                      width: 100px;

                      &::before {
                        content: "%";
                        position: absolute;
                        font-size: 12px;
                        line-height: 43px;
                        height: 41px;
                        right: 76px;
                        font-weight: bold;
                        color: #aeaeae;
                        margin-top: 4px;
                      }
                    }
                  }

                  &:first-child {
                    padding-left: 0;
                  }

                  .btn.btn-close {
                    color: #eaeaea;
                    margin-bottom: 0;

                    &:hover {
                      color: $accent-1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.full {
      display: table;
      width: 800px;
      margin: auto;
      height: calc(100% - 200px);

      .content {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
      }
    }
  }

  .ImageViewer {
    display: flex;
    width: 100vw;
    height: 100vh;

    .header {
      position: relative;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;

      .alert {
        position: absolute;
        top: 40px;
      }
    }

    div.title {
      display: block;
      position: absolute;
      margin: auto;
      top: 40px;
      width: 100%;
      text-align: center;

      label {
        text-transform: uppercase;
        margin-bottom: 0;
        color: #aeaeae;
        margin-right: 4px;
        font-weight: 500;
        font-size: 11px;
        padding-left: 16px;
      }
    }

    &.full-size {
      .AnnotateImage {
        min-width: 100%;

        .img-wrapper {
          padding: 100px 50px 100px 50px;
          -webkit-transition: padding 0.3s;
          -o-transition: padding 0.3s;
          transition: padding 0.3s;
        }
      }
    }

    .Aside {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      right: 0;
      top: 0;
      display: block;
      min-width: 400px;
      border-left: 1px solid #eaeaea;
      height: 100vh;
      z-index: 1;
      background-color: #fff;
      max-width: 400px;
      overflow-y: scroll;
      padding-bottom: 40px;

      .title-bar {
        margin-top: 8px;
        padding: 20px;

        &.share {
          h2 {
            margin-top: 30px;
            padding: 8px 12px 0 12px;
          }

          p {
            margin-top: 8px;
            padding: 8px 14px 0 14px;
          }
        }

        label {
          text-transform: uppercase;
          margin-bottom: 0;
          color: #aeaeae;
          margin-right: 4px;
          font-weight: 500;
          font-size: 11px;
          padding-left: 16px;
        }

        .text_input {
          margin-top: 30px;
          padding: 8px 12px 0 12px;

          &.edit {
            border: 0;
            padding: 0;
          }
        }

        textarea {
          min-height: auto;
        }
      }

      hr {
        margin: 0;
      }

      .comments {
        .comment-input-wrapper {
          padding: 0 20px;
          margin-top: 20px;
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            padding: 20px;
            display: table;
            width: 100%;
            position: relative;

            .btn.btn-control {
              position: absolute;
              display: none;
              height: 100%;
              text-align: right;
              vertical-align: middle;
              max-width: 370px;
              top: -12px;

              i {
                color: #757e8e;

                &:hover {
                  color: $accent-1;
                }
              }
            }

            &:hover {
              background-color: #f5f5f5;
              cursor: pointer;

              .btn.btn-control {
                display: table-cell;
              }
            }

            p {
              margin-left: 26px;
              font-size: 13px;

              &.name {
                font-weight: bold;
                color: $primary-dark;
              }

              &.date {
                text-align: right;
                font-size: 11px;
              }

              &.value {
                max-width: 260px;
              }
            }

            .tag {
              position: absolute;
              height: 18px;
              width: 18px;
              background-color: $accent-1;
              border-radius: 20px;
              text-align: center;
              color: #fff;
              z-index: 1;
              font-size: 11px;
              font-weight: 500;
              line-height: 20px;
              left: 50px;
              top: 46px;
            }

            .Avatar {
              position: relative;
              display: table-cell;
              width: 30px;
              margin-right: 20px;
              top: 8px;
              vertical-align: top;

              left: 8px;

              .user-name {
                display: none !important;
              }

              &:hover {
                .user-name {
                  display: block !important;
                  background-color: #757e8e;
                  padding: 4px 12px 4px 12px;
                  color: #fff;
                  font-size: 12px;
                  position: absolute;
                  border-radius: 14px;
                  text-transform: capitalize;
                  white-space: nowrap;
                  z-index: 1000;
                }
              }

              .user {
                min-width: 30px;
                max-width: 30px;
                height: 30px;
                color: #818a98;
                font-size: 12px;

                &:before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  left: -3px;
                  top: -3px;
                  border: 1px solid #eaeaea;
                }
              }
            }
          }
        }

        textarea {
          min-height: auto;
        }
      }

      &.closed {
        -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      }

      &.opened {
        -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }

  .body {
    margin: 16px 40px;
    display: table;
    height: calc(100vh - 60px);
    vertical-align: middle;
    position: relative;

    &.w-800 {
      width: 800px;
      max-width: 800px;
    }

    .content {
      vertical-align: middle;
      display: table-cell;
      top: -60px;

      div.image {
        display: table;
        vertical-align: middle;
        position: relative;
        top: 8px;
        float: right;

        div {
          display: table-cell;
          vertical-align: middle;

          &:first-child {
            width: 80px;
          }

          p {
            margin: 0;
            font-size: 11px;

            &:first-child {
              color: $primary-dark;
              font-size: 14px;
            }
          }
        }

        img.user {
          display: inline-block;
          border-radius: 50%;
          background-color: #eaeaea;
          width: 50px;
          height: 50px;
          margin: auto;
        }
      }
    }
  }

  .content.Insight {
    .Create {
      display: flex;
      flex-direction: row;

      div.bar {
        position: relative;
        top: 8px;
        min-height: 30px;
        max-height: 60px;
        width: 4px;
        background-color: $primary;
        margin-right: 60px;

        &:after {
          display: inline-block;
          content: url("/img/quotes.png");
          position: relative;
          left: 20px;
        }
      }

      textarea {
        border: 0;
        font-family: "AmericanTypewriter";
        font-size: 34px;
        background-color: transparent;
        line-height: 36px;
        text-justify: inter-word;
        font-weight: 520;
        letter-spacing: 0.8px;
        padding-bottom: 12px;
        resize: none;
      }
    }
  }

  .userInfo {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .img {
      margin-right: 12px;
    }

    .desc {
      position: relative;
      top: 4px;

      .name {
        color: $primary-dark;
      }
    }
  }
}

.UpdateProfile {
  .body {
    display: flex;
    flex-direction: row;
    margin: 0;
    height: auto;

    .left {
      width: 70%;
      margin-right: 30px;

      .profileName {
        display: flex;
        width: 100%;

        .form-group {
          width: 100%;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .right {
      width: 30%;

      .Avatar {
        width: 200px;

        .user,
        .Thumbnail .user {
          min-width: 200px;
          max-width: 200px;
          height: 200px;
          font-size: 80px;

          &:before {
            width: 210px;
            height: 210px;
          }
        }

        .user-name {
          display: none !important;
        }

        &:hover {
          .user-name {
            display: block !important;
            background-color: #757e8e;
            padding: 4px 12px 4px 12px;
            color: #fff;
            font-size: 12px;
            position: absolute;
            border-radius: 14px;
            text-transform: capitalize;
            white-space: nowrap;
            z-index: 1000;
          }
        }

        .upload {
          width: 200px;
          height: 200px;
          background-color: #f5f5f5;
          border-radius: 50%;
          text-align: center;
          position: relative;
          font-weight: bold;
          color: $primary-dark;

          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 210px;
            height: 210px;
            border-radius: 50%;
            left: -5px;
            top: -5px;
            border: 2px dashed $primary;
          }
        }
      }
    }
  }

  .btn.btn-primary {
    margin-left: 0 !important;
  }
}

.Notifications {
  .controls {
    margin-top: 12px;

    .control {
      display: table;
      margin-bottom: 16px;

      .desc {
        display: table-cell;
        width: 100%;
        padding: 4px 20px 4px 0px;

        p {
          font-size: 14px;
          line-height: 18px;
          color: $primary-dark;
        }
      }

      .toggle {
        display: table-cell;
        width: auto;
        max-width: 100px;
        padding: 4px;
        vertical-align: middle;
        text-align: center;
        padding: 4px 20px 4px 4px;
      }
    }
  }
}

.Advanced {
  & .multiselect_input {
    &.edit {
      height: 300px !important;
    }

    & .input_box {
      background-color: #fff;
      border: 2px solid #eaeaea;
    }

  }
}

.Experience {
  .multiselect_input {
    & .input_box {
      background-color: #fff;
      border: 2px solid #eaeaea;
    }
  }
}