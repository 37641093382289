@import "./_colors.scss";

.description-aside {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 800px;
}

.Aside {
  &.KanbanAside {
    width: 200px;
  }

  .Idea {
    width: 100%;

    hr.dashed {
      margin: 0 16px;
      border: 0;
      border-bottom: 1px dashed #eaeaea;
    }

    section {
      margin-top: 8px;
      padding: 0 20px;

      .userInfo {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        position: relative;
        padding-left: 16px;

        .img {
          margin-right: 12px;

          .avatar-stack {
            display: flex;
            flex-direction: row-reverse;
          }

          .Avatar {
            width: auto;
            position: relative;

            .user-name {
              display: none !important;
            }

            &:hover {
              .user-name {
                display: block !important;
                background-color: #757e8e;
                padding: 4px 12px 4px 12px;
                color: #fff;
                font-size: 12px;
                position: absolute;
                border-radius: 14px;
                text-transform: capitalize;
                white-space: nowrap;
                z-index: 1000;
              }
            }

            .user-name {
              display: none !important;
            }

            &:hover {
              .user-name {
                display: block !important;
                background-color: #757e8e;
                padding: 4px 12px 4px 12px;
                color: #fff;
                font-size: 12px;
                position: absolute;
                border-radius: 14px;
                text-transform: capitalize;
              }
            }

            &:last-child {
              left: 0px !important;
            }

            .user {
              min-width: 32px;
              max-width: 32px;
              height: 32px;

              &:before {
                height: 40px;
                width: 40px;
                left: -4px;
                top: -4px;
              }
            }
          }
        }
      }

      &.footer {
        position: fixed;
        bottom: 0;
        background-color: #fff;
        min-width: 500px;
      }

      &.header {
        display: table;
        width: 100%;
        margin-bottom: 8px;
        margin-top: 20px;

        .left {
          display: table-cell;
          vertical-align: middle;

          label {
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            color: #aaaaaa;
            padding-left: 16px;
          }

          div.status-btns {
            display: flex;
            padding-left: 6px;

            .btn.btn-status {
              width: 30px;
              padding: 8px !important;
              height: 30px;
              font-size: 16px;
              letter-spacing: 0.6px;
              text-transform: uppercase;
              color: #eaeaea;
              border: 1px solid #fff;
              background-color: #fff;

              &.love {
                i.fa-heart {
                  color: $accent-1;
                }
              }

              &.back_burner {
                i.fa-fire-alt {
                  color: $accent-3-dark;
                }
              }
            }
          }
        }

        .right {
          width: 50%;
          display: table-cell;
          vertical-align: middle;

          .controls {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 8px;

            .dropdown {
              padding: 4px;

              .dropdown-menu {
                left: -180px;
                top: -0px;
              }
            }
          }
        }
      }

      &.title-bar {
        display: table;
        margin-bottom: 12px;

        div {
          display: table-cell;
          width: 100%;
          vertical-align: top;

          &.first-child {
            max-width: 80%;
          }
        }

        p.date {
          font-size: 12px;
          color: #aaaaaa;
          margin-bottom: 2px;
          padding-left: 16px;
        }

        .Avatar {
          position: relative;
          left: -12px;
          top: 26px;

          .user-name {
            display: none !important;
          }

          &:hover {
            .user-name {
              display: block !important;
              background-color: #757e8e;
              padding: 4px 12px 4px 12px;
              color: #fff;
              font-size: 12px;
              position: absolute;
              border-radius: 14px;
              text-transform: capitalize;
              white-space: nowrap;
              z-index: 1000;
            }
          }

          .user {
            vertical-align: middle !important;
          }
        }

        h2 {
          font-size: 25px;
          font-weight: 500;
        }

        .text_input {
          padding: 8px 12px 0 12px;
          display: inline-block;
          width: calc(100% - 40px);

          &.edit {
            border: 0;
            padding: 0;
          }
        }
      }

      &.questions {
        margin-top: 24px;

        .question {
          margin-bottom: 20px;

          label {
            text-transform: none;
            color: #71798b;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
            padding: 0 16px;
          }

          p {
            margin-bottom: 4px;
            padding: 0 16px;
          }

          &.board {
            padding: 0 12px;
            position: relative;

            .btn.btn-link {
              position: absolute;
              width: auto;
              font-weight: normal;
              right: 8px;
              font-size: 11px;
              top: -8px;
            }

            label {
              padding: 0;
            }

            .SelectInput {
              position: relative;
              top: -4px;
            }
          }
        }
      }

      &.score {
        display: table;
        padding: 20px 0;

        div {
          display: table-cell;
          width: 100%;
          vertical-align: top;

          div.controls {
            display: flex;
            flex-direction: column;
            padding: 0 0 0 28px;
            width: 100%;

            div.control {
              label {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0.4px;
                color: #71798b;
              }
            }
          }

          &.total {
            width: 200px;

            div.box {
              position: relative;
              top: 20px;
              padding: 20px;
              background-color: #f9fdff;
              min-width: 100px;
              text-align: center;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;

              p {
                margin: 0;
                text-transform: capitalize;
              }
              h2 {
                margin: 0;
                font-size: 36px;
              }
            }
          }
        }
      }
    }
  }
}

.List.Ideas {
  .SearchBar {
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #eaeaea;
    position: relative;

    .search {
      &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        color: #aeaeae;
        font-style: normal;
        font-weight: 900;
        position: absolute;
        margin-left: 10px;
        margin-top: 8px;
      }

      input {
        text-indent: 20px;
        padding: 18px 14px 18px 14px;
        font-size: 14px;
      }
    }
  }

  .header {
    padding: 12px 16px 22px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    border-bottom: 1px solid #eaeaea;
    position: relative;

    label {
      text-transform: uppercase;
      color: #bac3ce;
      font-size: 11px;
      font-weight: 500;
      margin: 0 0 8px 0;
      letter-spacing: 0.6px;
    }

    .controls {
      position: absolute;
      top: 12px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      padding-right: 20px;

      .dropdown {
        .dropdown-menu {
          top: -8px;
          left: -190px;
        }
      }
    }

    p {
      font-size: 12px;
      line-height: 14px;
    }

    h3 {
      font-size: 22px;
      font-weight: 300;
      margin: 0 0 6px 0;
      color: $primary-dark;
      text-transform: capitalize;
    }
  }

  .description {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 800px;
  }

  .Avatar {
    position: relative;
    top: 10px;

    .user-name {
      display: none !important;
    }

    &:hover {
      .user-name {
        display: block !important;
        background-color: #757e8e;
        padding: 4px 12px 4px 12px;
        color: #fff;
        font-size: 12px;
        position: absolute;
        border-radius: 14px;
        text-transform: capitalize;
        white-space: nowrap;
        z-index: 1000;
      }
    }
  }

  .tag {
    position: relative;
    display: block !important;
    z-index: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 2px solid #fff;
    z-index: 1;
    top: -8px;
    left: 30px;

    &.none {
      background-color: transparent;
      border: 1px solid transparent;
    }

    &.back_burner {
      background-color: $accent-3-dark;
    }

    &.love {
      background-color: $accent-1;
    }

    i {
      color: #fff;
      font-size: 11px;
      position: relative;
      top: -5px;

      &.fa-heart {
        font-size: 10px;
      }
    }
  }
}

section.Kanban {
  padding: 20px;
  height: calc(100vh - 160px);
  width: 100%;

  div.board {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);

    .header {
      padding: 12px 10px 4px 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      .controls {
        position: absolute;
        top: 12px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        padding-right: 20px;

        .dropdown {
          .dropdown-menu {
            top: -8px;
            left: -190px;
          }
        }
      }

      label {
        text-transform: uppercase;
        color: #bac3ce;
        font-size: 11px;
        font-weight: 500;
        margin: 0 0 8px 0;
        letter-spacing: 0.6px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }

      h3 {
        font-size: 22px;
        font-weight: 300;
        margin: 0;
        color: $primary-dark;
        text-transform: capitalize;
      }
    }

    div.columns {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      height: 100%;
      position: relative;

      div.column {
        width: 100%;
        border-right: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
        overflow-y: scroll;

        div.head {
          width: 100%;
          text-align: center;
          background-color: #fff;
          display: table-cell;
          padding: 12px;
          border: 1px solid #eaeaea;
          border-right: 0;
          border-left: 0;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: $primary-dark;
          vertical-align: middle;

          i {
            font-size: 22px;
            margin-right: 8px;

            &.fa-heart {
              color: $accent-1;
            }

            &.fa-fire-alt {
              color: $accent-3-dark;
            }

            &.fa-lightbulb {
              color: #aeaeae;
            }
          }

          span {
            display: inline-block;
            position: relative;
            top: -4px;
          }
        }

        div.items {
          display: block;
          padding: 8px;
          overflow-y: scroll;
          max-height: 100%;
          flex: 1 1 auto;
          min-height: 0;

          div.item {
            display: block;

            width: 100%;
            margin-top: 8px;

            div.content {
              width: 100%;
              padding: 16px;
              font-size: 12px;
              background-color: #fff;
              border-radius: 4px;
              border: 1px solid #eaeaea;

              div {
                display: table-cell;
                vertical-align: top;
                width: auto;

                p {
                  font-size: 12px;
                  line-height: 14px;
                  margin: 0;

                  &.description {
                    font-size: 12px;
                    line-height: 18px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-width: 800px;
                  }
                }

                &.body {
                  width: 100%;

                  h4 {
                    font-size: 14px;
                    color: $primary-dark;
                  }

                  ul {
                    list-style: none;
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    li {
                      font-size: 12px;
                      margin: 0;
                      padding: 0;
                      background-color: transparent;
                      line-height: 20px;

                      label {
                        margin-bottom: 0;
                        color: #757e8e;
                        margin-right: 4px;
                      }

                      p {
                        display: inline-block;
                        margin: 0 4px 0 0;

                        font-size: 12px;
                        color: $primary-dark;
                        padding: 0;

                        &:after {
                          content: ",";
                        }

                        &:last-child {
                          font-size: 12px;

                          &:after {
                            content: "";
                          }
                        }
                      }
                    }
                  }
                }

                i {
                  text-align: center;
                  color: #eaeaea;
                  font-size: 18px;

                  &:hover {
                    cursor: pointer;
                    color: $accent-3;
                  }
                }

                .active {
                  color: $accent-3;
                }

                &:first-child {
                  text-align: center;
                  padding-right: 12px;
                }

                &:last-child {
                  text-align: center;
                  vertical-align: middle;
                  padding-left: 20px;
                  padding-right: 14px;

                  h4 {
                    font-size: 20px;
                    font-weight: 300;
                  }
                }
              }
            }

            &:hover {
              cursor: pointer;
            }

            &:active {
              cursor: grabbing;
            }

            &.dragging {
              .content {
                border: 1px solid #eaeaea;
                -webkit-box-shadow: 2px 2px 6px #eaeaea;
                -moz-box-shadow: 2px 2px 6px #eaeaea;
                box-shadow: 2px 2px 6px #eaeaea;

                -webkit-transform: rotate(-2deg);
                -ms-transform: rotate(-2deg);
                transform: rotate(-2deg);
              }
            }
          }
        }

        &:last-child {
          border-right: 0;

          div.head {
            border-right: 1px solid #eaeaea;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        &:first-child {
          div.head {
            border-left: 1px solid #eaeaea;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
  }
}
