.bullet-list {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  min-height: 29px;
  margin-left: 5px;
  position: relative;

  &::after {
    content: ' ';
    width: 15px;
    height: 100%;
    position: absolute;
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) , rgba(255, 255, 255, 1));
  }

  & li {
    padding: 4px 12px 4px 12px !important;
    background-color: #757e8e !important;
    color: #fff;
    font-size: 12px;
    border-radius: 14px !important;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    margin-right: 4px;
    white-space: nowrap;
    margin-bottom: 4px;
    position: relative;
    left: -4px;
    width: fit-content !important;
    margin-bottom: 8px !important;
    margin: inherit !important;
  }
}
