$column-sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

.flex {
  display: flex;

  & .fgrow-1 {
    flex-grow: 1;
  }

  &.frow {
    flex-direction: row !important;
  }

  &.column {
    flex-direction: column;
  }

  @each $size in $column-sizes {
    & .fcol-#{$size} {
      flex: $size;
    }
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }
}

.align-self {
  &__center {
    align-self: center;
  }
}

.align-center {
  align-items: center;
}
