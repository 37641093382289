@import "./_colors.scss";

.insight.create {
  width: 80%;

  &:focus-within {
    border: 1px #eee solid;
  }
}

.insight.delete.dropdown-menu {
  min-width: 125px;
}

button.link {
  color: $primary;
  font-size: 12px;
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.List.Insights {
  width: 450px !important;
  min-width: 450px !important;
  padding: 0 !important;
  max-height: calc(100vh - 61px);
  overflow: none;

  .search {
    padding: 12px 12px 0px 12px;

    margin-top: 12px;

    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f002";
      color: #aeaeae;
      font-style: normal;
      font-weight: 900;
      position: absolute;
      margin-left: 10px;
      margin-top: 8px;
    }

    input {
      text-indent: 20px;
      padding: 18px 14px 18px 14px;
      font-size: 14px;
    }
  }

  .scroll-container {
    height: calc(100vh - 161px);
    margin-top: 20px;
    border-top: 1px lightgrey solid;
    overflow: auto;
  }

  .NoResults {
    margin-top: 200px;
    text-align: center;

    p {
      margin-top: 12px;
      color: #b0bac7;
    }
  }

  ul.ListItem {
    list-style: none;
    display: block;
    display: table;
    flex-direction: column;
    padding: 0;

    &.placeholder p {
      min-width: 350px;
      &:before {
        display: none;
      }
    }

    li {
      display: table;
      border-radius: 0;
      margin: 0;
      box-shadow: unset;
      border: 0;
      background-color: transparent;
      border-bottom: 1px solid #eaeaea;
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #eaeaea;

      border-right: 1px solid transparent;
      border-left: 4px solid transparent;
      cursor: pointer;

      &.active {
        background-color: #fff;
        border-right: 1px solid #fff;
        border-left: 4px solid $primary;
        -webkit-box-shadow: 2px 2px 6px #eaeaea;
        -moz-box-shadow: 2px 2px 6px #eaeaea;
        box-shadow: 2px 2px 6px #eaeaea;
      }

      &:hover {
        background-color: #ffffff;
      }

      p {
        margin-bottom: 8px;
        font-size: 14px;
        color: $primary-dark;
        line-height: 18px;
        text-justify: inter-word;

        &.insight {
          &:before {
            display: block;
            content: url("/img/quotes_sm.png");
            position: relative;
            top: -2px;
          }
        }
      }

      &.active {
        box-shadow: unset !important;
      }

      .status {
        display: flex;
        flex-direction: row;
        font-size: 11px;
        width: 100%;

        p {
          &.date {
            font-size: 11px;
            color: #aaaaaa;
          }
        }

        .indicators {
          margin-left: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          p {
            display: inline-block;
            color: #b7c0cc;
          }

          i {
            margin-left: 4px;

            &.fa-flask {
              color: #b7c0cc;
            }

            &.fa-star,
            &.fa-key {
              color: $accent-3-dark;
            }
          }
        }
      }
    }
  }
}

.Aside {
  width: 100%;
  min-width: 500px;
  border-left: 1px lightgrey solid;

  hr.dashed {
    margin: 24px;
    border-top: 2px dashed #eaeaea;
  }

  .Insight {
    min-width: 100%;

    section {
      margin-top: 8px;
      padding: 0 40px;

      &.header {
        display: table;
        width: 100%;
        margin-bottom: 8px;
        margin-top: 20px;

        .left {
          display: table-cell;
          vertical-align: middle;

          .btn-key {
            width: auto;
            font-size: 11px;
            letter-spacing: 0.2px;
            color: #b7c0cc;
            text-transform: uppercase;
            padding-left: 0;

            i {
              margin-right: 6px;
            }

            &.active {
              color: $accent-3;
            }
          }
        }

        .right {
          width: 50%;
          display: table-cell;
          vertical-align: middle;

          .controls {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 8px;

            .dropdown {
              padding: 4px;

              .dropdown-menu {
                left: -180px;
                top: -0px;
              }
            }
          }
        }
      }

      &.details {
        margin-bottom: 20px;

        .insight {
          display: flex;
          flex-direction: row;
          margin-bottom: 30px;

          div.bar {
            position: relative;
            top: 8px;
            min-height: 30px;
            max-height: 60px;
            width: 4px;
            background-color: $primary;
            margin-right: 60px;

            &:after {
              display: inline-block;
              content: url("/img/quotes.png");
              position: relative;
              left: 20px;
            }
          }

          .text_input {
            width: 100%;
            position: relative;
            top: 8px;

            h1 {
              font-family: "AmericanTypewriter";
              font-size: 32px;
              word-break: break-word;
              padding: 4px 8px 0px 8px;
            }
          }
        }

        .userInfo {
          margin-top: 16px;
          display: flex;
          flex-direction: row;
          position: relative;

          .img {
            margin-right: 12px;

            .avatar-stack {
              display: flex;
              flex-direction: row-reverse;
            }

            .Avatar {
              width: auto;
              position: relative;

              .user-name {
                display: none !important;
              }

              &:hover {
                .user-name {
                  display: block !important;
                  background-color: #757e8e;
                  padding: 4px 12px 4px 12px;
                  color: #fff;
                  font-size: 12px;
                  position: absolute;
                  border-radius: 14px;
                  text-transform: capitalize;
                  white-space: nowrap;
                  z-index: 1000;
                }
              }

              &:last-child {
                left: 0px !important;
              }

              .user {
                min-width: 32px;
                max-width: 32px;
                height: 32px;

                &:before {
                  height: 40px;
                  width: 40px;
                  left: -4px;
                  top: -4px;
                }
              }
            }
          }

          .desc {
            position: relative;
            top: 4px;
            p {
              font-size: 11px;
              line-height: 12px;
            }
            .name {
              color: $primary-dark;
            }
          }
        }
      }

      &.attributes {
        .attr {
          margin-bottom: 20px;
          position: relative;

          .btn.btn-link {
            position: absolute;
            width: auto;
            font-weight: normal;
            right: 8px;
            font-size: 11px;
            top: -8px;
          }

          label {
            text-transform: uppercase;
            color: #757e8e;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 4px;
            text-indent: 14px;
          }

          p {
            color: #757e8e;
            font-size: 14px;
            text-indent: 14px;
          }

          .btn-primary-light {
            display: block;
            width: auto;
            padding: 8px 20px;
            margin-top: 12px;
            margin-left: 12px;

            &:hover {
              background-color: $primary;
              color: #fff;
            }
          }
        }
      }
    }

    ul.Experiments {
      margin: 0;
      padding: 0;
      padding: 0 16px;

      li {
        margin: 8px 0;
      }
    }

    ul.Notes {
      margin: 0;
      padding: 0;
      padding: 0 16px;

      li {
        margin-top: 8px;
        margin-bottom: 12px;
        border-left: 4px solid $primary;
        display: flex;
        flex-direction: row;
        padding: 12px;

        .Avatar {
          .user-name {
            display: none !important;
          }

          &:hover {
            .user-name {
              display: block !important;
              background-color: #757e8e;
              padding: 4px 12px 4px 12px;
              color: #fff;
              font-size: 12px;
              position: absolute;
              border-radius: 14px;
              text-transform: capitalize;
              white-space: nowrap;
              z-index: 1000;
            }
          }

          width: 20px;
          position: relative;
          left: 12px;

          .user {
            min-width: 32px;
            max-width: 32px;
            height: 32px;

            &:before {
              height: 40px;
              width: 40px;
              left: -4px;
              top: -4px;
            }
          }
        }

        .body {
          width: 100%;
          p {
            margin-left: 24px;
            color: $primary-dark;

            &.date {
              font-size: 10px;
              color: #757e8e;
            }
          }
        }

        .controls {
          width: 40px;
          visibility: hidden;

          .dropdown {
            li {
              padding: initial;
            }
            .dropdown-menu {
              left: -130px;
              min-width: 180px;
              top: -0px;
            }
          }
        }

        &:hover {
          cursor: pointer;

          .controls {
            visibility: visible;
          }
        }
      }
    }

    .AddNote {
      margin-top: 30px;
      padding: 16px 16px;
      display: flex;
      flex-direction: row;
      position: relative;

      &.no-notes {
        margin-top: 0px;
      }

      .Avatar {
        margin-right: 12px;
        position: relative;
        top: 0;

        .user-name {
          display: none !important;
        }

        &:hover {
          .user-name {
            display: block !important;
            background-color: #757e8e;
            padding: 4px 12px 4px 12px;
            color: #fff;
            font-size: 12px;
            position: absolute;
            border-radius: 14px;
            text-transform: capitalize;
            white-space: nowrap;
            z-index: 1000;
          }
        }

        .user {
          min-width: 32px;
          max-width: 32px;
          height: 32px;

          &:before {
            height: 40px;
            width: 40px;
            left: -4px;
            top: -4px;
          }
        }
      }

      .rich_text_input {
        width: 100%;
        min-height: 40px;

        textarea.form-control {
          min-height: 40px;
        }
      }

      p.rich_text_input {
        min-height: 40px;
        border: 2px dashed #eaeaea;
      }
    }
  }
}
.Modal.InsightAltitude {
  .content {
    top: unset;
  }
}

.Modal.LinkInsights {
  .content {
    max-height: calc(100vh - 100px);
    top: unset;
  }

  .header {
    max-width: 800px !important;

    .search {
      margin-top: 12px;

      &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        color: #aeaeae;
        font-style: normal;
        font-weight: 900;
        position: absolute;
        margin-left: 14px;
        margin-top: 14px;
      }

      input {
        font-size: 14px;
        text-indent: 24px;
        width: 100%;
      }
    }
  }

  .body {
    border-top: 1px solid #eaeaea;
    max-height: calc(100vh - 400px) !important;

    div.InsightSelector {
      display: flex;
      border-left: 4px solid $primary;
      margin-bottom: 22px;

      div.selector {
        padding: 8px 22px;
      }

      .avatar {
        margin-left: auto;

        .Avatar {
          width: auto;
          position: relative;
          top: 8px;

          .user-name {
            display: none !important;
          }

          &:hover {
            .user-name {
              display: block !important;
              background-color: #757e8e;
              padding: 4px 12px 4px 12px;
              color: #fff;
              font-size: 12px;
              position: absolute;
              border-radius: 14px;
              text-transform: capitalize;
              white-space: nowrap;
              z-index: 1000;
            }
          }

          .user {
            min-width: 32px;
            max-width: 32px;
            height: 32px;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0px;
            &::before {
              width: 42px;
              height: 42px;
            }
          }
        }
      }

      div.insight {
        display: flex;
        padding-top: 4px;
        padding-right: 8px;

        &:before {
          display: inline-block;
          content: url("/img/quotes.png");
          position: relative;
          margin-right: 12px;
          top: 4px;
        }

        p {
          display: inline-block;
          font-family: "AmericanTypewriter";
          font-size: 22px;
          word-break: break-word;
          color: #4a4a4a;
          line-height: 26px;
        }

        .highlight {
          padding: 0;
          background-color: $accent-3;
        }
      }
    }

    ul {
      li {
        display: table;
        border-left: 4px solid $primary;

        div {
          display: table-cell;
          margin-bottom: 12px;

          &.selector {
            padding-top: 8px;
            min-width: 54px;
          }
        }

        .CheckBox {
          .checkmark {
            position: absolute;
            left: unset;
          }
        }
      }
    }
  }

  .cntrl-bar {
    justify-content: flex-start;
    padding: 12px 20px;

    i {
      margin-right: 4px;
      font-size: 12px;
    }

    .btn {
      width: 240px !important;
    }

    .btn-link {
      width: unset !important;
      font-weight: normal;
      padding: 0 !important;
    }
  }
}
