@import "_vars";
@import "_animations";
@import "./_colors.scss";

$font: "Roboto", sans-serif;

.dropdown {
  .dropdown-button {
    position: relative;
    top: -2px;
    background-color: transparent;
    color: #eaeaea;
    border: 2px solid #eaeaea;
    border-radius: 4px;
    background-color: #fff;
    padding: 2px 8px;

    i {
      position: relative;
      top: 1px;
    }

    &:hover {
      border-color: #b7c0cc;

      i {
        color: #b7c0cc;
      }
    }
  }

  .dropdown-menu {
    visibility: hidden;
    border-radius: 2px;
    min-width: 225px;
    border: 1px solid #eaeaea;
    box-shadow: -4px 2px 8px #b7c0cc;
    padding: 0;
    z-index: 2 !important;

    &.show {
      visibility: visible;
      -webkit-animation: swing-in-top-fwd 0.5s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both;
    }

    div {
      &.Collapsible {
        padding: 8px 8px 4px 16px;
      }

      .Collapsible__contentInner {
        margin-top: 6px;
      }

      &.split-above {
        border-top: 1px solid #eaeaea !important;
      }

      a {
        font-weight: 400;
        display: block;
        width: 100%;
        color: #757e8e;
        text-transform: capitalize !important;

        i {
          color: #eaeaea;
          margin: 0 8px 8px;
          font-size: 16px;
          position: relative;
          top: 2px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    li {
      border: 0 !important;
      font-size: 14px;
      font-weight: normal;
      margin: 0 !important;

      &.split-above {
        border-top: 1px solid #eaeaea !important;
      }

      a {
        padding: 8px 8px 4px 8px;
        display: block;
        width: 100%;
        color: #757e8e;
        text-transform: capitalize !important;

        i {
          color: #eaeaea;
          margin: 0 8px 8px;
          font-size: 16px;
          position: relative;
          top: 2px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background-color: #fbfcfc;

        a,
        i {
          color: #757e8e;
        }
      }

      button.btn.dropdown-menu-item-btn {
        text-align: left;
        display: block;
        width: 100%;
        color: #757e8e;
        text-transform: capitalize !important;
        border-width: 0;
        padding: 8px 8px 4px 8px;
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        border-radius: 0;

        i {
          color: #eaeaea;
          margin: 0 8px 8px;
          font-size: 16px !important;
          position: relative;
          text-align: center;
          top: 2px;
          width: 22px;
        }

        &:hover {
          text-decoration: none;
          background-color: #fbfcfc;
          color: #757e8e;

          i {
            color: #757e8e;
          }
        }

        &.danger {
          color: $accent-1;
          &:hover {
            i {
              color: $accent-1;
            }
          }
        }
      }
    }
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $accent-2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.SelectInput {
  position: relative;

  button {
    width: 100%;
    background-color: #fff;
    border: 2px solid #eaeaea;
    padding: 8px 6px;
    border-radius: 4px;
    text-align: left;
    text-indent: 12px;
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 8px;
    margin-bottom: 0;

    .placeholder {
      color: #b9bdc3;
    }

    &:after {
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      color: #757e8e;
      font-style: normal;
      font-weight: 900;
      float: right;
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;

      &:after {
        color: $primary-dark;
      }
    }
  }

  &.disabled {
    button {
      background-color: #eaeaea;

      &:hover {
        cursor: not-allowed;

        &:after {
          color: #757e8e;
        }
      }
    }
  }

  &.error {
    button {
      border-width: 2px;
    }
  }

  .menu {
    width: 100%;
    position: absolute;
    display: none;
    z-index: 1;
    border: 2px solid #eaeaea;
    padding: 8px;
    border-radius: 0px;
    background-color: #fff;

    .search {
      border-bottom: 1px solid #eaeaea;

      input {
        text-align: left;
        font-size: 12px;
        padding: 12px;
        width: 100%;
        border: 1px solid transparent;

        &:hover {
          background-color: #fff;
        }

        &:focus {
          border: 1px solid transparent;
        }
      }
    }
  }

  &.edit {
    .menu {
      display: block;
      margin-top: -2px;
      padding: 0;
      -webkit-box-shadow: 2px 2px 6px #eaeaea;
      -moz-box-shadow: 2px 2px 6px #eaeaea;
      box-shadow: 2px 2px 6px #eaeaea;

      .results {
        max-height: 160px;
        overflow-y: scroll;

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          border: 0;
          display: flex;
          flex-direction: column;

          li {
            padding: 12px;
            font-size: 14px;
            text-transform: capitalize;
            display: table-cell;
            width: 100%;
            border-bottom: 1px solid #eaeaea;
            text-indent: 8px;
            position: relative;
            color: $primary-dark;
            font-weight: 500;

            &.over {
              cursor: pointer;
              background-color: $primary;
              color: #fff;

              &:after {
                position: absolute;
                display: inline-block;
                content: "click to select";
                text-transform: lowercase;
                right: 0;
                font-size: 12px;
                margin-right: 20px;
                font-weight: 400;
                bottom: 2px;
              }

              &.new {
                &:after {
                  content: "click to add new tag";
                }
              }

              &.active {
                background-color: $accent-1;

                &:after {
                  content: "click to remove";
                }
              }
            }

            img {
              height: 40px;
              width: 40px;
              margin-right: 12px;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.Altitudes {
    button {
      min-height: 60px;
      border: 2px solid transparent;

      &:hover {
        border: 2px dashed #eaeaea;
        &:after {
          display: block;
        }
      }

      &:after {
        display: none;
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

      &:after {
        position: relative;
        top: 12px;
      }
    }

    &.noVal {
      button {
        min-height: unset;

        &:after {
          position: relative;
          top: 0px;
        }
      }
    }

    &.edit {
      button {
        border: 2px solid #eaeaea;

        &:after {
          display: block;
        }
      }
    }
  }

  &.Confidences {
    button {
      min-height: 60px;
      border: 2px solid transparent;

      &:hover {
        border: 2px dashed #eaeaea;
        &:after {
          display: inline-block;
        }
      }

      &:after {
        display: none;
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

      &:after {
        position: relative;
        top: 12px;
      }
    }

    svg {
      margin-top: 10px;
    }

    &.noVal {
      button {
        min-height: unset;

        &:after {
          position: relative;
          top: 0px;
        }
      }
    }

    &.edit {
      button {
        border: 2px solid #eaeaea;

        &:after {
          display: block;
        }
      }
    }
  }

  &.currency-select {
    button {
      display: block;
      // text-transform: lowercase;

      &:second-letter {
        // text-transform: uppercase;
      }

      &.has-symbol {
        &.dollar {
          &::before {
            content: "$";
          }
        }

        &.euro {
          &::before {
            content: "€";
          }
        }

        &.pound {
          &::before {
            content: "£";
          }
        }

        &::before {
          position: relative;
          margin-right: 0.5rem;
          // left: 14px;
          // top: 6px;
          // line-height: 43px;
          // height: 41px;
          // font-weight: bold;
          // color: #aeaeae;
        }
      }
    }

    .menu {
      .results {
        ul {
          li {
            display: block;
            text-transform: lowercase;

            &.has-symbol {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              text-transform: unset;
            }

            div.symbol {
              &.dollar {
                &::before {
                  content: "$";
                }
              }

              &.euro {
                &::before {
                  content: "€";
                }
              }

              &.pound {
                &::before {
                  content: "£";
                }
              }

              &::before {
                position: relative;
                // left: 14px;
                // top: 6px;
                // line-height: 43px;
                // height: 41px;
                // font-weight: bold;
                // color: #aeaeae;
              }
            }

            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

.multiselect_input {
  position: relative;

  ul.input_box {
    border-radius: 4px;
    border: 2px solid transparent;
    margin: 0;
    padding: 8px 12px 4px 12px;
    list-style: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
    min-height: 46px;

    li {
      padding: 4px 12px 4px 12px;
      background-color: #757e8e;
      color: #fff;
      font-size: 12px;
      border-radius: 14px;
      text-transform: capitalize;
      letter-spacing: 0.2px;
      margin-right: 4px;
      white-space: nowrap;
      margin-bottom: 4px;
      position: relative;
      left: -4px;
    }
  }

  &:hover {
    cursor: pointer;
    ul {
      font-size: 16px;
      border: 2px dashed #eaeaea;
    }
  }

  &.create {
    ul.input_box {
      background-color: #fff;
      border: 2px solid #eaeaea;
    }

    &.edit {
      ul.input_box {
        background-color: #fff;
        border: 2px solid $primary;
      }
    }

    &.error {
      ul.input_box {
        border-color: $accent-1;
      }
    }
  }

  .menu {
    width: 100%;
    position: absolute;
    display: none;
    z-index: 1;
    border: 2px solid #eaeaea;
    padding: 8px;
    border-radius: 0px;
    background-color: #fff;
  }

  &.edit {
    ul {
      border: 2px solid #eaeaea;

      li {
        button {
          background-color: transparent;
          border: 0;
          color: #fff;
          padding: 0;
          margin-left: 12px;

          &:after {
            content: "\00d7";
            color: #fff;
            font-weight: 300;
          }

          &:hover {
            &:after {
              color: $accent-1;
            }
            cursor: pointer;
          }
        }
      }
    }

    .menu {
      display: block;
      padding: 0;
      position: absolute;
      top: +46px;
      -webkit-box-shadow: 2px 2px 6px #eaeaea;
      -moz-box-shadow: 2px 2px 6px #eaeaea;
      box-shadow: 2px 2px 6px #eaeaea;

      .search {
        border-bottom: 2px solid #eaeaea;

        &:before {
          font-family: "Font Awesome 5 Free";
          content: "\f002";
          color: #aeaeae;
          font-style: normal;
          font-weight: 900;
          position: absolute;
          margin-left: 10px;
          margin-top: 10px;
        }

        input {
          font-size: 14px;
          padding: 12px;
          padding-left: 34px;
          width: 100%;
          border: 0;
        }
      }

      div.label {
        display: block;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
        background-color: #eaeaea;
        width: 100%;
        margin: 0;
        padding: 4px 8px;
        letter-spacing: 0.6px;
      }

      .results {
        max-height: 160px;
        overflow-y: scroll;

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          border: 0;
          display: flex;
          flex-direction: column;

          li {
            padding: 12px;
            font-size: 14px;
            text-transform: capitalize;
            display: flex;
            width: 100%;
            border-bottom: 1px solid #eaeaea;
            text-indent: 8px;
            position: relative;
            color: $primary-dark;
            font-weight: 500;
            align-items: center;

            .category {
              width: 100px;
              text-align: center;

              .tag {
                padding: 4px 8px;
                border-radius: 16px;
                background-color: #757e8e;
                width: fit-content;
                font-size: 12px;
                color: #fff;
                margin: auto;
                text-indent: 0;
              }
            }

            &.over {
              cursor: pointer;
              background-color: $primary;
              color: #fff;

              &:after {
                position: absolute;
                display: inline-block;
                content: "click to select";
                text-transform: lowercase;
                right: 0;
                font-size: 12px;
                margin-right: 20px;
                font-weight: 400;
              }

              &.new {
                &:after {
                  content: "click to add new tag";
                }
              }

              &.active {
                background-color: $accent-1;

                &:after {
                  content: "click to remove";
                }
              }
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

p.rich_text_input,
p.comment_input {
  padding: 8px 12px;
  margin: 0;
  border: 2px dashed transparent;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border: 2px dashed #eaeaea;
  }
}

p.comment_input {
  border: 1px solid #eaeaea;

  &:hover {
    cursor: pointer;
    border: 1px solid #eaeaea;
  }
}

div.comment_input {
  div.input {
    border: 2px solid $primary;
    border-radius: 4px;
    position: relative;

    textarea {
      border: 0px;
      resize: none;
      height: 120px;
    }

    .ctrls {
      position: absolute;
      width: auto;
      right: 0;
      width: 40px;
      top: 90px;

      .btn.btn-ctrl {
        position: relative;
        margin: 0;
        left: 8px;
        padding: 0;
        width: auto;
        color: #757e8e;
        font-size: 20px;

        &:hover,
        &.active {
          color: $accent-1;
        }
      }
    }
  }

  .ctrl-bar {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btn.btn-primary {
      max-width: 120px;
      padding: 8px 8px;
      text-transform: uppercase;
      font-size: 10px;
      border-radius: 2px;
      letter-spacing: 0.2px;

      &.disabled {
        background-color: #b7c0cc;
        border: 0px;
      }
    }
  }
}

div.text_input {
  padding: 0;
  margin: 0;
  border: 2px dashed transparent;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border: 2px dashed #eaeaea;
  }

  &.edit {
    div.input {
      display: block;
      border: 2px solid #eaeaea;
      border-radius: 0.25rem;
      position: relative;

      input {
        border: 0;
      }

      &.percent {
        width: 100px;

        &::before {
          content: "%";
          position: absolute;
          font-size: 12px;
          line-height: 43px;
          height: 41px;
          right: +12px;
          top: -6px;
          font-weight: bold;
          color: #aeaeae;
          margin-top: 4px;
        }
      }
    }
  }
}

div.DatePicker {
  padding: 0;
  margin: 0;
  border: 2px dashed transparent;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border: 2px dashed #eaeaea;
  }

  &.active {
    border: 2px dashed transparent;
  }
}

div.MonthPicker {
  width: 180px;
  height: 41px;
  margin: 8px 0 0 12px;
  padding: 8px 6px;
  border: 2px solid #eaeaea;

  &:hover {
    cursor: pointer;
  }

  .MonthPickerBox {
    font-family: $font;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.847);
    text-align: center;
  }

  .rmp-container.show {
    right: 500px;
    left: auto;
    z-index: 9999;

    .rmp-overlay {
      left: 0;
    }

    .rmp-popup {
      border: 2px solid #eaeaea;
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 20px, 0);
    }

    .rmp-popup.light,
    .rmp-popup.light.range .rmp-pad {
      color: $primary-dark;
      background: white;
    }

    .rmp-popup.light.range .rmp-pad {
      label,
      b {
        font-family: $font;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .rmp-popup.light.range .rmp-btn {
      font-family: $font;
      font-size: 15px;
      font-weight: 300px;
    }

    .rmp-popup.light.range .rmp-btn.active:not(.rmp-tab) {
      background: $primary;
    }

    .rmp-popup.light.range .rmp-btn.select:not(.rmp-tab) {
      background: $primary-light;
    }

    .rmp-popup.light.range .rmp-btn.disable:not(.rmp-tab) {
      background: #fbfcfc;

      &:hover {
        background: #fbfcfc;
      }
    }

    .rmp-popup.light.range .rmp-btn:not(.rmp-tab):not(.disable) {
      &:hover {
        color: white;
        background: $primary;
      }
    }
  }
}

.FilterBar {
  position: relative;
  max-height: calc(100vh - 61px);
  overflow: auto;

  .cta {
    margin: 8px;
    padding: 16px 8px 0 8px;
    border-top: 1px solid #eaeaea;
    bottom: 0;
    text-align: center;
    position: relative;
    bottom: 0;

    .btn-info-link {
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      color: $primary;
      text-transform: none;

      &:hover {
        color: $primary;
      }
    }
  }

  .viewCtrls {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    .btn.btn-icon {
      padding: 0;
      margin: 0;
      width: initial;
      margin-right: 12px;
      color: #757e8e;

      &.active {
        color: $primary;
      }
    }
  }

  .addCtrls {
    padding: 0 20px;
    .btn.btn-primary {
      width: 100%;
      max-width: 100%;
      padding: 4px;
      font-size: 14px;
    }
  }

  .sortCtrls {
    padding: 0 16px;
    display: flex;
    flex-direction: row;

    label {
      font-size: 12px;
      margin-right: 6px;
      color: #b0bac7;
    }

    .dropdown {
      button {
        border: 0px;
        background: transparent;
        padding: 0;
        font-size: 12px;
        position: relative;
        top: -4px;

        &:after {
          content: "";
          display: inline-block;
          margin-left: 5px;

          height: 0;
          vertical-align: 0.155em;
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
        }
      }

      .dropdown-menu {
        top: -6px;
        left: -50px;
        min-width: 200px;
      }
    }
  }

  .Filter {
    margin-top: 12px;
    padding: 0 20px 0 20px;

    &.placeholder {
      h4 {
        background-color: #eaeaea;
        animation: fading 1.5s infinite;
        width: 80%;
        height: 18px;
      }

      ul li {
        p {
          background-color: #eaeaea;
          animation: fading 1.5s infinite;
          width: 100%;
          height: 12px;
        }
      }
    }

    h4,
    .Collapsible__trigger {
      font-size: 14px;
      margin-bottom: 8px;
      color: $primary-dark;
      text-transform: capitalize;
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: 12px;
        text-transform: capitalize;
        margin-bottom: 6px;

        p {
          font-size: 12px;
          color: $primary-dark;
          margin: 0;

          &:last-child {
            margin-left: auto;
            color: #aeaeae;
          }

          i {
            margin-right: 4px;

            &.fa-heart {
              color: $accent-1;
            }

            &.fa-fire-alt {
              color: $accent-3-dark;
            }

            &.fa-key {
              color: $accent-3-dark;
            }

            &.fa-star {
              color: $accent-3-dark;
            }
          }

          .icon {
            height: 24px;
            align-self: flex-end;
          }
        }

        .btn-link {
          width: auto;
          font-size: 12px;
          padding: 0;
          font-weight: normal;
          margin: 0;
        }

        .ScoreSelector {
          max-width: 100%;
          min-width: 100% !important;
          margin-bottom: 4px;
        }
      }
    }
  }

  .Inbox {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px;
    position: relative;

    h4 {
      text-indent: 20px;
      font-size: 14px;
      margin-bottom: 8px;
      color: $primary-dark;
    }

    section {
      margin-bottom: 12px;

      .btn-link.btn-add {
        margin: 0;
        font-weight: normal;
        font-size: 12px;
        width: auto;
        padding: unset;
        text-align: left;
        float: right;
        margin-right: 8px;

        i {
          margin-right: 4px;
          text-decoration: none;
          font-size: 10px;
          position: relative;
          top: -1px;
        }
      }

      ul {
        padding: 0;
        list-style: none;
        margin: 0;

        &:first-child {
          margin-top: 12px;
        }

        li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          font-size: 12px;
          text-transform: capitalize;
          margin-bottom: 2px;
          border-left: 4px solid transparent;

          a,
          .btn-link,
          p {
            font-size: 12px;
            color: $primary-dark;
            margin: 0;
            font-weight: normal;
            width: auto;
            padding: unset;
            text-align: left;
            margin-left: 16px;
            position: relative;
          }

          .btn-link {
            &:last-child {
              color: $primary;
              margin-right: 20px;
            }
          }

          &.active {
            border-left: 4px solid $primary;
          }
        }
      }
    }
  }
}

.Alert {
  position: fixed;
  bottom: 0;
  z-index: 9000;
  background-color: $accent-2;
  color: #fff;
  display: block;
  padding: 8px 90px 8px 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: 20px;
  left: 0px;
  text-indent: 12px;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.Alert.danger {
  background-color: $accent-1;
}

.Avatar,
.Thumbnail {
  padding: 0;
  width: 50px;
  display: table;
  margin: auto;
  padding-bottom: 4px;

  div {
    display: table-cell;
    vertical-align: middle;
  }

  .user {
    background-color: #eaeaea;
    position: relative;
    border-radius: 50%;
    letter-spacing: 0.8px;
    color: #757e8e;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    min-width: 42px;
    max-width: 42px;
    text-align: center;
    height: 42px;

    &.sm {
      min-width: 25px !important;
      max-width: 25px !important;
      height: 25px !important;
      font-size: 12px !important;

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        left: -4px;
        top: -4px;
        border: 2px solid #eaeaea;
      }

      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        min-width: 25px;
        min-height: 25px;
      }
    }

    &.focus {
      &:before {
        border: 2px solid $primary !important;
      }
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      left: -5px;
      top: -5px;
      border: 2px solid #eaeaea;
    }
  }

  .image {
    background-color: #eaeaea;
    position: relative;
    border-radius: 50%;
    letter-spacing: 0.8px;
    color: #757e8e;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      left: -5px;
      top: -5px;
      border: 2px solid #eaeaea;
    }
  }

  img.image {
    width: auto !important;
  }
}

.ExperimentAvatar {
  padding: 0;
  width: 80px;
  display: table;
  margin: auto;
  padding-bottom: 4px;
  position: relative;

  &.sm {
    width: 40px;

    .image {
      min-width: 40px;
      max-width: 40px;
      height: 40px;

      &:before {
        width: 48px !important;
        height: 48px !important;
        max-width: 48px;
        min-width: 48px;
        left: -4px;
        top: -4px;
      }
    }
  }

  div {
    display: table-cell;
    vertical-align: middle;
  }

  .image {
    background-color: #eaeaea;
    position: relative;
    border-radius: 50%;
    letter-spacing: 0.8px;
    color: #757e8e;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      left: -5px;
      top: -5px;
      border: 2px solid #eaeaea;
    }

    img.image {
      object-fit: cover;
    }
  }

  &.no-img {
    width: 40px;

    .image {
      min-width: 40px;
      max-width: 40px;
      height: 40px;

      &:before {
        position: absolute;
        width: 50px;
        height: 50px;
      }
    }
  }

  .tag {
    position: absolute;
    display: block !important;
    z-index: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #fff;
    z-index: 1;
    bottom: 0%;
    left: 70%;

    &.adobe {
      background-image: url("/img/apps/adobe.png");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position: center;
      width: 20px !important;
    }
  }
}

.StarControl {
  .btn.btn-star {
    margin: 0;
    padding: 4px;

    i.fa-star {
      color: #eaeaea;
    }

    &.active {
      i.fa-star {
        color: $accent-3-dark;
      }
    }
  }
}

.ExpIcon {
  padding: 0;
  width: 40px;
  height: 40px;
  display: table;
  margin: auto;
  padding-bottom: 4px;
  position: relative;
  top: 4px;

  .text {
    background-color: $primary;
    position: relative;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      left: -5px;
      top: -5px;
      border: 2px solid $primary;
    }
  }

  .tag {
    position: relative;
    display: block !important;
    z-index: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #fff;
    z-index: 1;
    top: -12px;
    left: 28px;

    &.adobe {
      background-image: url("/img/apps/adobe.png");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position: center;
      width: 20px !important;
    }
  }
}

.invite.row {
  i {
    font-size: 28px;
  }
  .fa-envelope-open {
    color: $primary;
  }
  .fa-ellipsis-h {
    color: #ddd;
  }
}

.center {
  text-align: center;
}

.ScoreSelector {
  display: block;
  height: 20px;
  padding-bottom: 16px;
  min-width: 300px !important;

  ul.ticks {
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
    justify-content: space-around;

    li {
      display: table-cell;
      width: 20%;
      font-size: 12px;
      text-transform: capitalize;
      color: #aeaeae;
      text-align: center;
      font-size: 12px;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    margin: 4px 0;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: #eaeaea;
    border-radius: 25px;
  }

  input[type="range"]::-webkit-slider-thumb {
    border: 4px solid $primary;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4.1px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #eaeaea;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 12px;
    cursor: pointer;

    background: #eaeaea;
    border-radius: 25px;
  }

  input[type="range"]::-moz-range-thumb {
    border: 4px solid $primary;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background: #ffffff;
    cursor: pointer;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #eaeaea;

    border-radius: 50px;
  }
  input[type="range"]::-ms-fill-upper {
    background: #eaeaea;
    border-radius: 50px;
  }
  input[type="range"]::-ms-thumb {
    border: 4px solid $primary;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background: #ffffff;
    cursor: pointer;
    height: 12px;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #eaeaea;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #eaeaea;
  }

  &.none {
    input[type="range"]::-webkit-slider-thumb {
      border: 4px solid #eaeaea;
    }

    input[type="range"]::-moz-range-thumb {
      border: 4px solid #eaeaea;
    }
  }
}

.WorkSheet {
  width: 800px;
  margin: auto;
  position: relative;
  padding-bottom: 40px;

  label {
    font-size: 10px;
    text-transform: uppercase;
    color: #aeaeae;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
  }

  h1 {
    &.title {
      font-size: 40px;

      &[placeholder]:empty:before {
        content: attr(placeholder);
        color: #757e8e;
      }
    }
  }

  p {
    font-size: 18px;
    color: #333;
    line-height: 20px;
  }

  ul {
    margin: 0;
  }

  .DraftEditor-root {
    color: $primary-dark;

    h1 {
      font-size: 22px;
      letter-spacing: 0.4;
      color: $primary-dark;
    }

    h2 {
      font-size: 18px;
      letter-spacing: 0.2;
      color: $primary-dark;
      font-weight: 500;
    }

    .public-DraftEditorPlaceholder-inner {
      font-style: italic;
    }
  }

  .Toolbar {
    position: absolute;
    z-index: 2;
    border-radius: 4px;
    background-color: #333;
    display: flex;
    flex-direction: row;
    width: 240px;
    -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
    box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);

    ul.toolbar-items {
      margin: 0;
      padding: 0;
      width: auto;

      li {
        display: table-cell;
        vertical-align: middle;
        height: 28px;
        width: 40px;
        color: #fff;
        text-align: center;
        vertical-align: middle;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          border-right: 1px solid #515151;
        }

        i {
          font-size: 14px;
        }

        label {
          font-size: 15px;
          font-weight: bold;
          margin: 0;
          color: #fff;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }

        &.active {
          background-color: #fff;

          i,
          label {
            color: #333;
          }
        }
      }
    }

    ul:last-child {
      li:last-child {
        border-right: 1px solid transparent;
      }
    }
  }
}

.ExpStatus {
  .status {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 16px;
    border: 1px solid #eaeaea;
    min-width: 80px;
    font-size: 12px;
    margin-bottom: 4px;
    text-transform: capitalize;
    color: #eaeaea;
    line-height: 12px;
    min-height: 22px;
    text-align: center;

    &.winner {
      color: $accent-2;
      border-color: $accent-2;
    }

    &.flat {
      color: $accent-3;
      border-color: $accent-3;
    }

    &.loss {
      color: $accent-1;
      border-color: $accent-1;
    }
  }
}

.ImpactValue {
  width: 100%;
  position: relative;

  &.nomargin {
    input {
      margin-top: 0 !important;
    }

    &::before {
      top: -1px !important;
    }
  }

  input {
    width: 100%;
    border: 0px;
    height: 41px;
    font-size: 12px;
    padding: 0 8px;
    border: 2px solid #eaeaea;

    &::placeholder {
      font-size: 12px;
      position: relative;
      top: -2px;
      color: #aeaeae;
    }
  }

  &.currency {
    &.dollar {
      &::before {
        content: "$";
      }
    }

    &.euro {
      &::before {
        content: "€";
      }
    }

    &.pound {
      &::before {
        content: "£";
      }
    }

    &::before {
      position: absolute;
      font-size: 14px;
      left: 14px;
      top: 6px;
      line-height: 43px;
      height: 41px;
      font-weight: bold;
      color: #aeaeae;
    }

    input {
      text-indent: 12px;
    }
  }

  &:last-child {
    input {
      border-right: 1px solid transparent;

      &:focus {
        background-color: #fff;
        border: 1px solid $primary;
      }
    }
  }
}

.AnnotateImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;

  div.header {
    width: 100%;
    display: block;
    text-align: center;
    height: 30px;
    //top: 80px;

    div.alert {
      margin: auto;
      padding: 6px 8px;
      background-color: $accent-1;
      color: #fff;
      width: auto;
      border-radius: 20px;
      width: 300px;
      font-size: 11px;
      font-weight: 400;
      -webkit-animation: scale-in-center 0.3s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      i {
        margin-right: 8px;
        font-size: 16px;
        position: relative;
        top: 1px;
      }
    }
  }

  .img-wrapper {
    margin: auto;
    padding: 0 0px 0 0px;
    vertical-align: middle;
    -webkit-transition: padding 0.3s;
    -o-transition: padding 0.3s;
    transition: padding 0.3s;
    padding-left: 30px;
    padding-right: 30px;
    overflow-y: auto;
    max-height: calc(100vh - 250px);

    img {
      -webkit-box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
      box-shadow: 0 0 20px rgba(31, 37, 50, 0.2);
      border: 1px solid #d6d6dd;
      width: auto;
      max-width: 100%;
      margin: auto;
    }

    .box {
      position: absolute;
      border: dashed 4px $accent-1;

      .marker {
        max-height: 30px;
        max-width: 30px;
        color: #fff;
        background-color: $accent-1;
        font-weight: bold;
        text-align: center;
        line-height: 30px;
        border-radius: 20px;
        position: relative;
        top: -16px;
        left: -16px;
      }
    }
  }
}

.ImageCarousel {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 12px;
  z-index: 1;
  border-top: 1px solid #eaeaea;
  background-color: #fff;
  justify-content: center;
  box-shadow: -4px 2px 8px #b7c0cc;
  border-radius: 4px;
  height: 40px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    height: auto;
  }

  .thmb {
    height: 140px;
    width: 140px;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 750ms cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0 12px;
    border: 2px solid #eaeaea;
    border-radius: 4px;

    &.active {
      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        position: relative;
        top: -146px;
        left: -8px;
        background-color: $primary;
        color: #fff;
        padding: 4px;
        border-radius: 50%;
      }
    }

    &:hover {
      box-shadow: 2px 2px 8px 2px hsla(0, 0%, 80%, 0.47);
    }

    img {
      position: relative;
      line-height: 0;
      height: 136px;
      width: 136px;
      object-fit: cover;
    }
  }
}

.ToolTip {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: -2px;

  .Icon {
    color: #fff;
    background-color: $primary;

    display: flex;
    text-align: center;
    border-radius: 50%;

    height: 18px;
    width: 18px;
    line-height: 20px;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      font-size: 8px;
      position: relative;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .TipBox {
    display: none;
    position: relative;
    background: #040506;
    min-width: 200px;
    box-shadow: -4px 2px 8px #b7c0cc;
    min-width: 200px;
    padding: 16px;
    border-radius: 4px;

    p {
      color: #fff;
    }
  }

  .TipBox:after,
  .TipBox:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .TipBox:after {
    border-top-color: #040506;
    border-width: 6px;
    margin-left: -6px;
  }
  .TipBox:before {
    border-top-color: #040506;
    border-width: 6px;
    margin-left: -6px;
  }

  &.open {
    .TipBox {
      position: absolute;
      display: block;
      z-index: 1;
      top: -96px;
      left: -91px;
    }
  }
}

.AdobeMetric {
  .input_box {
    border: 2px solid #eaeaea !important;
    padding: 8px 8px 0px 8px !important;
  }

  div.menu {
    box-shadow: 0 0 0 0 transparent !important;

    .results {
      ul {
        padding: 0 !important;

        li {
          margin: 0 !important;
        }
      }
    }
  }
}
